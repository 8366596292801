import { FormControl, Label, Input, FormErrorText, Button } from '@happyfoxinc/react-ui'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'

import SlideOverModal from 'Layout/SlideOverModal'

import inviteAgentValidationSchema from './invite-agent-validation-schema'
import api from 'Services/api'

const InviteAgentModal = (props) => {
  const { onHide } = props

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(inviteAgentValidationSchema)
  })

  const [inviteAgent, inviteAgentResult] = api.useInviteAgentMutation()

  const handleInviteFormSubmit = async (data) => {
    try {
      const payload = {
        email: data.email
      }

      const promise = inviteAgent(payload).unwrap()

      toast.promise(promise, {
        loading: 'Inviting admin',
        success: `Invited admin '${data.email}'. Sucessfully...`,
        error: `Error while inviting admin '${data.email}'. Try again.`
      })
      await promise
      onHide()
    } catch (error) {
      onHide()
    }
  }

  const disableSubmitButton = isSubmitting || inviteAgentResult.isLoading

  return (
    <SlideOverModal title='Invite admin' onHide={onHide}>
      <form onSubmit={handleSubmit(handleInviteFormSubmit)}>
        <FormControl isInvalid={errors.email}>
          <Label>Email</Label>
          <Input {...register('email')} placeholder='' />
          {errors.email && <FormErrorText>{errors.email.message}</FormErrorText>}
        </FormControl>
        <Button variant='primary' type='submit' disabled={disableSubmitButton}>
          Submit
        </Button>
      </form>
    </SlideOverModal>
  )
}

export default InviteAgentModal
