import { useCallback, useEffect, useRef } from 'react'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const DEFAULT_PROMPT_MESSAGE = 'You will lose any unsaved changes. Are you sure you want to leave this page?'

export const Prompt = (props) => {
  const { when: isBlocking = false, message = DEFAULT_PROMPT_MESSAGE } = props

  const unblock = useRef()
  const clearBlocker = useCallback(() => {
    if (unblock.current) {
      unblock.current()
    }

    unblock.current = null
  }, [])

  const createBlocker = useCallback(() => {
    const unblocker = history.block((tx) => {
      const shouldUnblock = window.confirm(message)

      if (shouldUnblock) {
        // unblock naviagation
        clearBlocker()

        // Retry the transition.
        tx.retry()
      }
    })

    return unblocker
  }, [message, clearBlocker])

  useEffect(() => {
    if (!isBlocking) {
      return clearBlocker()
    }

    if (!unblock.current) {
      unblock.current = createBlocker()
    }
  }, [createBlocker, isBlocking, clearBlocker])

  return null
}

export default history
