import { Fragment } from 'react'
import { Heading } from '@happyfoxinc/react-ui'

import styles from './SlideOverModal.module.css'
import CloseIcon from 'Icons/close.svg'

const SlideOverModal = (props) => {
  const { title, onHide, children, show = true } = props

  if (!show) {
    return null
  }

  return (
    <Fragment>
      <div className={styles.Overlay} onClick={onHide} />
      <div className={styles.Container}>
        <div className={styles.Header}>
          <div className={styles.Close} onClick={onHide}>
            <CloseIcon widht='18' height='18' />
          </div>
          <Heading level={2}>{title}</Heading>
        </div>
        <div className={styles.Content}>{children}</div>
      </div>
    </Fragment>
  )
}

export default SlideOverModal
