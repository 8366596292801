import { createApi } from '@reduxjs/toolkit/query/react'

import { rtkBaseQuery } from '../../../../../../api'

export const sharePointApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'sharePointApi',
  tagTypes: ['Sharepoint', 'SharepointSites'],
  refetchOnMountOrArgChange: 5,
  endpoints: (builder) => ({
    getApp: builder.query({
      query: () => '/v1/apps/sharepoint',
      providesTags: ['Sharepoint']
    }),
    getSites: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/sharepoint/sites/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['SharepointSites']
    }),
    uninstall: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/sharepoint',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Sharepoint']
    }),
    removeSharePointSite: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/apps/sharepoint/sites/sync/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['SharepointSites']
    }),
    searchSites: builder.query({
      query: (siteName) => {
        return {
          url: `/v1/apps/sharepoint/sites`,
          method: 'GET',
          params: {
            site_name: siteName
          }
        }
      }
    }),
    addSharePointSite: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/apps/sharepoint/sites/sync`,
          method: 'POST',
          body: {
            site_id: id
          }
        }
      },
      invalidatesTags: ['SharepointSites']
    })
  })
})

export const {
  useGetAppQuery,
  useUninstallMutation,
  useRemoveSharePointSiteMutation,
  useGetSitesQuery,
  useLazySearchSitesQuery,
  useAddSharePointSiteMutation
} = sharePointApi

export default sharePointApi
