import { Fragment } from 'react'
import { Heading, Text } from '@happyfoxinc/react-ui'

import AgentsTable from './AgentsTable'

const ManageAgents = () => {
  return (
    <Fragment>
      <Heading>Manage Admins</Heading>
      <Text style='muted'>
        Admins can access this admin module to view dashboard, connect knowledge sources, enable conversational
        ticketing, view reports, and invite other admins.
      </Text>
      <AgentsTable />
    </Fragment>
  )
}

export default ManageAgents
