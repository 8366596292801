import { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, Label, Input, Button, FormErrorText, Heading } from '@happyfoxinc/react-ui'
import { useNavigate } from 'react-router-dom'

import styles from './AnswerForm.module.css'
import BackIcon from 'Icons/back-arrow-circle.svg'

import Tips, { Tip } from '../Tips'
import TrixEditor from 'Components/TrixEditor'
// import ReactSelect from 'Components/ReactSelect'
// import { Feature } from 'Components/Features'

// import api from 'Services/api'

const AnswerForm = (props) => {
  const { isLoading, onSubmit, onSaveAndAdd, onDeleteAnswer } = props
  const navigate = useNavigate()

  // const {
  //   data: userGroupsData,
  //   isLoading: isUserGroupLoading,
  //   isFetching: isUserGroupFetching
  // } = api.useGetUserGroupsQuery()
  // const { results: userGroups } = userGroupsData

  const {
    register,
    control,
    formState: { isSubmitting, errors }
    // watch
  } = useFormContext()

  const disableSubmitButton = isSubmitting || isLoading
  // const visibility = watch('visibility')

  const handleBackClick = () => {
    navigate('../')
  }

  return (
    <Fragment>
      <Heading className={styles.Title}>
        <BackIcon className={styles.BackButton} width='0.9em' height='0.9em' onClick={handleBackClick} /> Answers
      </Heading>
      <div className={styles.Container}>
        <div className={styles.MainContent}>
          <div className={styles.AnswerContent}>
            <form>
              <FormControl isInvalid={errors.name}>
                <Label>Answer Title</Label>
                <Input className={styles.TitleInput} {...register('name')} />
                {errors.name && <FormErrorText>{errors.name.message}</FormErrorText>}
              </FormControl>

              <FormControl isInvalid={errors.content}>
                <Controller
                  control={control}
                  name='content'
                  render={({ field: { value, onChange } }) => {
                    return <TrixEditor defaultValue={value} onChange={onChange} />
                  }}
                />
                {errors.content && <FormErrorText>{errors.content.message}</FormErrorText>}
              </FormControl>

              {/* <FormControl>
                <div className={styles.AnswerVisibilityContainer}>
                  <Feature features={['user_groups']}>
                    <div className={styles.AnswerVisibilty}>
                      <Label>Show answer group to</Label>
                      <Select {...register('visibility')}>
                        <option value='everyone'>Everyone</option>
                        <option value='specificGroups'>Specific group(s)</option>
                      </Select>
                    </div>
                  </Feature>

                  <div className={styles.VisibilityGroup}>
                    {visibility === 'specificGroups' && (
                      <FormControl isInvalid={errors.visibleToGroups}>
                        <Controller
                          name='visibleToGroups'
                          control={control}
                          shouldUnregister
                          render={({ field }) => {
                            return (
                              <ReactSelect
                                {...field}
                                isMulti
                                isClearable={false}
                                options={userGroups}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isLoading={isUserGroupFetching || isUserGroupLoading}
                              />
                            )
                          }}
                        />
                        {errors.visibleToGroups && <FormErrorText>{errors.visibleToGroups.message}</FormErrorText>}
                      </FormControl>
                    )}
                  </div>
                </div>
              </FormControl> */}

              <div className={styles.ButtonContainer}>
                <Button variant='primary' disabled={disableSubmitButton} onClick={onSubmit}>
                  Save
                </Button>

                <Button
                  variant='secondary'
                  disabled={disableSubmitButton}
                  className={styles.SaveAdd}
                  onClick={onSaveAndAdd}
                >
                  Save and Add
                </Button>

                {!onDeleteAnswer && (
                  <Button className={styles.DeleteButton} variant='link-muted' onClick={handleBackClick}>
                    Cancel
                  </Button>
                )}
                {onDeleteAnswer && (
                  <Button className={styles.DeleteButton} variant='link-danger' onClick={onDeleteAnswer}>
                    Delete
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className={styles.SidebarContent}>
          <Tips title='Tips to add answers content'>
            <Tip>Once you have created an answer, hit Save to add more</Tip>
            <Tip>
              Ensure your answers are clear, concise, and consist of the required keywords — Assist AI will
              automatically parse Answers for keywords and phrases
            </Tip>
            <Tip>
              These Answers will be displayed to users as search results when they type their question directly in a
              conversation with Assist AI.
            </Tip>
          </Tips>
        </div>
      </div>
    </Fragment>
  )
}

export default AnswerForm
