import FEATURE_FLAGS, { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'
import { createContext, useContext } from 'react'

const NoRender = () => null

const FeaturesContext = createContext([])

const useFeaturesContext = () => useContext(FeaturesContext)

const canAllowFeature = (features, requiredFeatures) => {
  return requiredFeatures.every((feature) => features.includes(feature))
}

const KNOWN_FEATURES = [
  FEATURE_FLAGS.USER_GROUPS,
  FEATURE_FLAGS.CONVERSATIONAL_TICKETING,
  FEATURE_FLAGS.PREMIUM_INTEGRATIONS,

  // custom client only feature names
  CLIENT_FEATURE_FLAGS.ENABLE_TICKETING,
  CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE
]

const FeaturesProvider = ({ features, children }) => {
  return <FeaturesContext.Provider value={features}>{children}</FeaturesContext.Provider>
}

const getAllFeatures = (allFeatures) => {
  return allFeatures.filter((featureName) => KNOWN_FEATURES.includes(featureName))
}

export const useFeature = (requiredFeatures = []) => {
  const allFeatures = useFeaturesContext()
  const allKnownFeatures = getAllFeatures(allFeatures)

  return canAllowFeature(allKnownFeatures, requiredFeatures)
}

export const Feature = (props) => {
  const { fallback = <NoRender />, children, features: requiredFeatures, negate = false } = props
  const allFeatures = useFeaturesContext()

  if (!requiredFeatures || requiredFeatures.length === 0) {
    return children
  }

  const allKnownFeatures = getAllFeatures(allFeatures)

  const isFeatureAllowed = canAllowFeature(allKnownFeatures, requiredFeatures)
  const isAllowed = negate ? !isFeatureAllowed : isFeatureAllowed

  if (!isAllowed) {
    return fallback
  }

  return children
}

export default FeaturesProvider
