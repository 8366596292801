import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import { InstallAppBase } from '../../../components/InstallApp'
import { useAppDetailContext } from '../../AppDetailContext'
import SharePointSites from './SharePointSites'

const ManageSharePoint = (props) => {
  const { app } = props
  const { title } = useAppDetailContext()

  return (
    <Fragment>
      <InstallAppBase>
        <Heading level={2}>
          Successfully connected to {title}. <br /> The account is tied to{' '}
          <Text isInline style='muted'>
            {app.user_email_id}
          </Text>
        </Heading>
      </InstallAppBase>
      <SharePointSites app={app} />
    </Fragment>
  )
}

export default ManageSharePoint
