import { Route, Routes, useNavigate } from 'react-router-dom'

import PageLoader from 'Components/PageLoader'

import { useGetAppQuery } from './simpplr.service'

import InstallSimpplr from './InstallSimpplr'
import ManageSimpplr from './ManageSimpplr'
import SimpplrAuthForm from './SimpplrAuthForm'

const Simpplr = () => {
  const { isLoading, data: app = {} } = useGetAppQuery()
  const navigate = useNavigate()

  if (isLoading) {
    return <PageLoader />
  }

  if (!app.is_linked) {
    return <InstallSimpplr app={app} />
  }

  const handleAuthFormCancel = () => {
    navigate('./')
  }

  return (
    <Routes>
      <Route
        path='auth'
        element={<SimpplrAuthForm onCancel={handleAuthFormCancel} onAuth={handleAuthFormCancel} app={app} />}
      />
      <Route path='*' element={<ManageSimpplr app={app} />} />
    </Routes>
  )
}

export default Simpplr
