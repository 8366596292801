import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { setupListeners } from '@reduxjs/toolkit/query'

import reducer from './reducer'
import rootSaga from './saga'

const sagaMiddleware = createSagaMiddleware()

const reducers = reducer.getReducers()
const services = reducer.getServices()

export const createStore = () => {
  const store = configureStore({
    reducer: {
      ...reducers,
      ...services.reducers
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(sagaMiddleware, ...services.middlewares)
    },
    devTools: {
      name: 'Assist AI - Admin'
    }
  })

  sagaMiddleware.run(rootSaga)

  setupListeners(store.dispatch)
  return store
}
