import Spinner from './spinner.svg'
import Logo from './logo.svg'
import styles from './AppLoader.module.css'

const AppLoader = ({ isLoading = false, children }) => {
  if (isLoading) {
    return (
      <div className={styles.Container}>
        <Spinner width='180' height='180' />
        <Logo width='60' height='60' className={styles.Logo} />
      </div>
    )
  }

  return children
}

export default AppLoader
