import { createApi } from '@reduxjs/toolkit/query/react'

import { rtkBaseQuery } from '../api'
import { providesList, invialidateListById, invialidateList } from 'Utils/rtk-query'

export const api = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'api',
  tagTypes: [
    'Profile',
    'Account',
    'UserGroups',
    'Dashboard',
    'Answers',
    'Answer',
    'Notifications',
    'Agents',
    'SaaSApps',
    'Apps'
  ],
  refetchOnMountOrArgChange: 10,
  endpoints: (builder) => ({
    // me
    getProfile: builder.query({
      query: () => 'v1/profile',
      providesTags: ['Profile']
    }),

    // account
    getAccount: builder.query({
      query: () => 'v1/account',
      providesTags: ['Account']
    }),

    // user groups
    getUserGroups: builder.query({
      query: (params) => {
        return {
          url: `/v1/user-groups`,
          method: 'GET',
          params
        }
      },
      providesTags: ['UserGroups']
    }),
    getUserGroup: builder.query({
      query: (id) => `/v1/user-groups/${id}`,
      providesTags: ['UserGroups']
    }),
    createUserGroup: builder.mutation({
      query: (data) => {
        return {
          url: 'v1/user-groups',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['UserGroups']
    }),
    updateUserGroup: builder.mutation({
      query: (data) => {
        return {
          url: 'v1/user-groups',
          method: 'PATCH',
          body: data
        }
      },
      invalidatesTags: ['UserGroups']
    }),
    deleteUserGroup: builder.mutation({
      query: (id) => {
        return {
          url: `v1/user-groups/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['UserGroups']
    }),

    // dashboard
    getDashboard: builder.query({
      query: () => 'v1/dashboard',
      providesTags: ['Dashboard']
    }),

    // answer
    listAnswers: builder.query({
      query: (params) => {
        return {
          url: `/v1/answers`,
          method: 'GET',
          params
        }
      },
      providesTags: ['Answers']
    }),
    getAnswerById: builder.query({
      query: (id) => `/v1/answers/${id}`,
      providesTags: ['Answer']
    }),
    createAnswer: builder.mutation({
      query: (payload) => {
        return {
          url: '/v1/answers',
          method: 'POST',
          body: payload
        }
      },
      invalidatesTags: ['Answers']
    }),
    updateAnswer: builder.mutation({
      query: (data) => {
        const { id, ...payload } = data

        return {
          url: `/v1/answers/${id}`,
          method: 'PATCH',
          body: payload
        }
      },
      invalidatesTags: ['Answer']
    }),
    deleteAnswer: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/answers/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Answers', 'Answer']
    }),

    // notifications
    getNotifications: builder.query({
      query: () => 'v1/notifications',
      providesTags: ['Notifications']
    }),
    updateNotfications: builder.mutation({
      query: (payload) => {
        return {
          method: 'PATCH',
          url: 'v1/notifications',
          body: payload
        }
      },
      invalidatesTags: ['Notifications']
    }),

    // agents
    getAgents: builder.query({
      query: (params) => {
        return {
          method: 'GET',
          url: '/v1/admins',
          params
        }
      },
      providesTags: (response) => providesList(response?.results, 'Agents')
    }),
    deleteAgent: builder.mutation({
      query: (id) => {
        return {
          method: 'DELETE',
          url: `v1/admins/${id}`
        }
      },
      invalidatesTags: (_result, _error, id) => invialidateListById('Agents', id)
    }),
    inviteAgent: builder.mutation({
      query: (data) => {
        return {
          method: 'POST',
          url: `v1/admins/invite`,
          body: data
        }
      },
      invalidatesTags: invialidateList('Agents')
    }),
    updateAgent: builder.mutation({
      query: (data) => {
        const { id, ...payload } = data
        return {
          method: 'PATCH',
          url: `/v1/admins/${id}`,
          body: payload
        }
      },
      invalidatesTags: (_result, _error, data) => invialidateListById('Agents', data.id)
    }),

    // saas
    getSassApps: builder.query({
      query: () => 'v1/saas',
      providesTags: ['SaaSApps']
    }),
    connectSaasApp: builder.mutation({
      query: (key) => {
        return {
          method: 'POST',
          url: `v1/saas/${key}`
        }
      },
      invalidatesTags: ['SaaSApps']
    }),
    disconnectSaasApp: builder.mutation({
      query: (key) => {
        return {
          method: 'DELETE',
          url: `v1/saas/${key}`
        }
      },
      invalidatesTags: ['SaaSApps']
    }),

    // Apps
    getApps: builder.query({
      query: () => 'v1/apps',
      providesTags: ['Apps']
    })
  })
})

export default api
