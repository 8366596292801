import { createSlice } from '@reduxjs/toolkit'

export const name = 'mock'

const initialState = {}

export const mockSlice = createSlice({
  name,
  initialState,
  reducers: {}
})

export const reducer = mockSlice.reducer
