import { Navigate, Route, Routes } from 'react-router-dom'

import AppsList from './AppList'
import AppDetails from './AppDetails'

const Apps = () => {
  return (
    <Routes>
      <Route path='category/*' index element={<AppsList />} />
      <Route path=':appName/*' element={<AppDetails />} />
      <Route path='' element={<Navigate to='category' replace />} />
      <Route path='*' element={<Navigate to='category' replace />} />
    </Routes>
  )
}

export default Apps
