import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

import ErrorBoundary from 'Components/ErrorBoundary'
import AppLoader from 'Components/AppLoader'
import Toast from 'Components/Toast'
import Main from 'Layout/Main'

import Dashboard from './Dashboard'
import Apps from './Apps'
import Answers from './Answers'
// import Sass from './Saas'
import ManageAgents from './ManageAgents'
// import UserGroups from './UserGroups'
import Notifications from './Notifications'

import api from 'Services/api'
import FeaturesProvider, { Feature } from 'Components/Features'
import { PlanProvider } from 'Components/Plan'
import { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'

const ROUTE_FEATURE_GROUP = {
  user_groups: 'user_groups',
  notifications: 'notifications'
}

const ROUTE_FEATURES = {
  // [ROUTE_FEATURE_GROUP.user_groups]: [FEATURE_FLAGS.USER_GROUPS],
  [ROUTE_FEATURE_GROUP.notifications]: [CLIENT_FEATURE_FLAGS.ENABLE_TICKETING]
}

const FeatureProtected = ({ group, ...rest }) => {
  return <Feature features={ROUTE_FEATURES[group]} fallback={<Navigate to='/dashboard' replace />} {...rest} />
}

const getFeatureFlags = (account) => {
  if (!account) {
    return []
  }

  const flags = [...account.enabled_features]

  if (account.is_ticketing_integration_connected) {
    flags.push(CLIENT_FEATURE_FLAGS.ENABLE_TICKETING)
  }

  if (account.is_dm_workflow_enabled) {
    flags.push(CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE)
  }

  return flags
}

const Protected = () => {
  const profile = api.useGetProfileQuery()
  const account = api.useGetAccountQuery()
  const userGroups = api.useGetUserGroupsQuery()

  const isLoading = profile.isLoading || account.isLoading || userGroups.isLoading

  const planDetails = {
    planName: account.data?.plan_name,
    integrationsLimit: account.data?.integrations_limit
  }

  const featureFlags = getFeatureFlags(account.data)

  return (
    <ErrorBoundary>
      <Toast />
      <PlanProvider planDetails={planDetails}>
        <FeaturesProvider features={featureFlags}>
          <AppLoader isLoading={isLoading}>
            <Routes>
              <Route path='/' element={<Main />}>
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='apps/*' element={<Apps />} />
                <Route path='answers/*' element={<Answers />} />
                {/* <Route path='saas' element={<Sass />} /> */}
                <Route path='admins' element={<ManageAgents />} />
                {/* <Route
                  path='user-groups/*'
                  element={
                    <FeatureProtected group={ROUTE_FEATURE_GROUP.user_groups}>
                      <UserGroups />
                    </FeatureProtected>
                  }
                /> */}
                <Route
                  path='notifications'
                  element={
                    <FeatureProtected group={ROUTE_FEATURE_GROUP.notifications}>
                      <Notifications />
                    </FeatureProtected>
                  }
                />
                <Route path='*' element={<Navigate to='dashboard' replace />} />
              </Route>
            </Routes>
          </AppLoader>
        </FeaturesProvider>
      </PlanProvider>
    </ErrorBoundary>
  )
}

export default Protected
