import styles from './AgentsTable.module.css'

export const Status = (props) => {
  const user = props.cell.value

  let statusText = 'Deactivated'

  if (user.status === 'invited') {
    statusText = 'Invited'
  } else if (user.status === 'active') {
    statusText = 'Active'
  }

  return <div className={styles[statusText]}>{statusText}</div>
}
