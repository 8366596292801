import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { TableOption, TableOptions } from '@happyfoxinc/react-ui'

import parseErrorMessage from 'Utils/error-message-parser'
import api from 'Services/api'

const AnswerOptions = (props) => {
  const { id } = props

  const [deleteAnswer] = api.useDeleteAnswerMutation()

  const handleDeleteClick = useCallback(() => {
    const yes = window.confirm('Are you sure to delete this answer.')

    if (!yes) {
      return
    }

    const promise = deleteAnswer(id).unwrap()
    toast.promise(promise, {
      loading: 'Deleting Answer...',
      success: 'Answer deleted successfully.',
      error: parseErrorMessage('Unable to delete answer. Try again...')
    })
  }, [deleteAnswer, id])

  return (
    <TableOptions>
      <TableOption onClick={handleDeleteClick}>Delete</TableOption>
    </TableOptions>
  )
}

export default AnswerOptions
