import * as yup from 'yup'

const happyfoxHelpdeskAuthFormValidationSchema = yup
  .object()
  .shape({
    accountUrl: yup.string().trim().required('Account URL is required').url('Invalid account url')
  })
  .required()

export default happyfoxHelpdeskAuthFormValidationSchema
