import styles from './Topbar.module.css'

import SlackLogo from 'Images/brands/slack.svg'
// import BellIcon from 'Icons/bell.svg'
import HelpIcon from 'Icons/help.svg'

import api from 'Services/api'

// import GlobalSearch from './GlobalSearch'
import UserActions from './UserActions'
import { openUrl } from 'Utils/url'

const Topbar = () => {
  const { data: account } = api.useGetAccountQuery()
  const handleWorkspaceNameClick = () => {
    if (account.is_enterprise_slack_install) {
      openUrl('https://app.slack.com')
    } else {
      openUrl(`https://slack.com/app_redirect?app=${account.slack_app_id}&team=${account.slack_team_id}`)
    }
  }

  return (
    <div className={styles.Container}>
      {/* <GlobalSearch />s */}
      <div className={styles.Actions}>
        {/* <div className={styles.IconAction}>
          <BellIcon width='1em' height='1em' />
        </div> */}
        <div className={styles.IconAction} onClick={() => openUrl('https://support.happyfox.com/kb/section/230/')}>
          <HelpIcon width='1em' height='1em' />
        </div>
        <div className={styles.IconAction}>
          <UserActions />
        </div>
      </div>
      <div className={styles.WorkspaceItem} onClick={handleWorkspaceNameClick}>
        <SlackLogo height='20' width='20' /> {account.workspace_name}
      </div>
    </div>
  )
}

export default Topbar
