import { useDropdownContext, Dropdown, DropdownItem, Small } from '@happyfoxinc/react-ui'
import { Fragment, useCallback } from 'react'

import styles from './UserActions.module.css'

import api from 'Services/api'
import { redirect } from 'Utils/url'

const UserDropdown = () => {
  const { data: user } = api.useGetProfileQuery()

  const handleLogoutClick = useCallback(async () => {
    const yes = window.confirm('Are you sure to logout?')
    if (!yes) {
      return
    }
    // do logout here
    redirect('logout')
  }, [])

  return (
    <Fragment>
      <DropdownItem>
        <Small size='xs'>{user.name}</Small>
        <br />
        <Small size='xs' style='muted'>
          {user.email}
        </Small>
      </DropdownItem>
      <DropdownItem onClick={handleLogoutClick} disabled={false}>
        Logout
      </DropdownItem>
    </Fragment>
  )
}

const Action = () => {
  const { data: user } = api.useGetProfileQuery()
  const { getReferenceProps } = useDropdownContext()

  return (
    <div {...getReferenceProps()}>
      <img src={user.avatar_url} className={styles.ProfilePic} />
    </div>
  )
}

const UserActions = () => {
  return <Dropdown id='user-action-root' offset={15} action={<Action />} dropdown={<UserDropdown />} />
}

export default UserActions
