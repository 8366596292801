import { Route, Routes, useNavigate } from 'react-router-dom'

import PageLoader from 'Components/PageLoader'

import { useGetAppQuery } from './confluence.service'

import InstallConfluence from './InstallConfluence'
import ManageConfluence from './ManageConfluence'
import ConfluenceAuthForm from './ConfluenceAuthForm'

const Confluence = () => {
  const { isLoading, data: app = {} } = useGetAppQuery()
  const navigate = useNavigate()

  if (isLoading) {
    return <PageLoader />
  }

  if (!app.is_linked) {
    return <InstallConfluence app={app} />
  }

  const handleAuthFormCancel = () => {
    navigate('./')
  }

  return (
    <Routes>
      <Route
        path='auth'
        element={<ConfluenceAuthForm onCancel={handleAuthFormCancel} onAuth={handleAuthFormCancel} app={app} />}
      />
      <Route path='*' element={<ManageConfluence app={app} />} />
    </Routes>
  )
}

export default Confluence
