import React, { Fragment, useCallback } from 'react'
import toast from 'react-hot-toast'
import { TableOptions, TableOption } from '@happyfoxinc/react-ui'

import api from 'Services/api'
// import EditAgent from '../EditAgent'

const AgentOptions = (props) => {
  // const { id , agent } = props
  const { id } = props

  // const [showEditAgentModal, setShowEditAgentModal] = useState(false)
  const [deleteAgent] = api.useDeleteAgentMutation()
  // const { data: currentUser } = api.useGetProfileQuery()

  const handleDeleteClick = useCallback(() => {
    const yes = window.confirm('Are you sure to delete this admin.')

    if (!yes) {
      return
    }

    const promise = deleteAgent(id).unwrap()
    toast.promise(promise, {
      loading: 'Deleting admin...',
      success: 'Admin deleted successfully.',
      error: 'Unable to delete admin. Try again....'
    })
  }, [id, deleteAgent])

  // const handleEditAgentClick = () => {
  //   setShowEditAgentModal(true)
  // }

  // const handleHideEditAgentModal = () => {
  //   setShowEditAgentModal(false)
  // }

  return (
    <Fragment>
      {/* {showEditAgentModal && <EditAgent onHide={handleHideEditAgentModal} agent={agent} />} */}
      <TableOptions>
        {/* {currentUser.id === id && <TableOption onClick={handleEditAgentClick}>Edit</TableOption>} */}
        <TableOption onClick={handleDeleteClick}>Delete</TableOption>
      </TableOptions>
    </Fragment>
  )
}

export default AgentOptions
