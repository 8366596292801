import { Button } from '@happyfoxinc/react-ui'

import styles from './UnInstallApp.module.css'
import IndeterminateCircle from 'Icons/indeterminate-circle.svg'

const UnInstallApp = (props) => {
  const { onUninstall, isLoading = false } = props

  return (
    <div className={styles.Container}>
      Status <span className={styles.Status}>This app is currently enabled</span>
      <Button
        variant='link-danger'
        className={styles.UninstallButton}
        role='button'
        onClick={onUninstall}
        disabled={isLoading}
      >
        Uninstall this app <IndeterminateCircle width='1em' height='1em' />
      </Button>
    </div>
  )
}

export default UnInstallApp
