import { TableActions, PaginationControls, Table, TableHead, TableBody, TableRow, Th, Td } from '@happyfoxinc/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTable, usePagination } from 'react-table'
import { useSearchParams } from 'react-router-dom'

import PageLoader from 'Components/PageLoader'
import debounce from 'Utils/debounce'

import * as Cells from './AgentCells'
import AgentOptions from './AgentOptions'
import InviteAgent from '../InviteAgent'
import api from 'Services/api'
import styles from './AgentsTable.module.css'

const DEFAULT_PAGE = 1
const DEFAULT_PAGE_SIZE = 15

const getDefaultParams = () => {
  const params = new URLSearchParams(location.search)

  const paramsSize = params.get('size') || DEFAULT_PAGE_SIZE
  const paramsPage = params.get('page') || DEFAULT_PAGE

  const page = isNaN(paramsPage) ? DEFAULT_PAGE : parseInt(paramsPage)
  const size = isNaN(paramsSize) ? DEFAULT_PAGE_SIZE : parseInt(paramsSize)

  return {
    page,
    size
  }
}

const getInitialApiSearchParams = (params) => {
  return {
    offset: (params.page - 1) * params.size,
    limit: params.size
  }
}

const AgentsTable = () => {
  const { data: currentUser } = api.useGetProfileQuery()

  const pageParams = useMemo(getDefaultParams, [])
  const [, setSearchParams] = useSearchParams()

  const [queryParams, setQueryParams] = useState(getInitialApiSearchParams(pageParams))
  const [pageSize] = useState(pageParams.size)

  const { isLoading, data = {} } = api.useGetAgentsQuery(queryParams)
  const { results: users = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Status',
        accessor: (row) => row,
        Cell: Cells.Status
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: users,
      initialState: {
        pageIndex: pageParams.page - 1,
        pageSize
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize)
    },
    usePagination
  )

  useEffect(() => {
    const params = {}

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)

    setSearchParams(urlPrams, { replace: true })
  }, [pageIndex, pageSize, debouncedSetQueryParams, setSearchParams])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <div className={styles.Container}>
      <TableActions>
        <PaginationControls
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
        <InviteAgent />
      </TableActions>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps()

                    return (
                      <Th key={key} {...headerProps}>
                        {column.render('Header')}
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()
            const agent = row.original

            return (
              <TableRow key={key} {...rowProps}>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()
                  return (
                    <Td key={key} {...cellProps}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}

                <Td>
                  {rows.length > 0 && currentUser.email !== agent.email && <AgentOptions {...agent} agent={agent} />}
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default AgentsTable
