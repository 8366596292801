import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { Heading, Text, Button } from '@happyfoxinc/react-ui'

import { useAppDetailContext } from '../../AppDetailContext'
import { useUninstallMutation } from './simpplr.service'
import UnInstallApp from '../../../components/UnInstallApp'
import { InstallAppBase } from '../../../components/InstallApp'
import SimpplrSites from './SimpplrSites'
import parseErrorMessage from 'Utils/error-message-parser'

const ManageSimpplr = (props) => {
  const { app } = props
  const { title } = useAppDetailContext()
  const navigate = useNavigate()

  const [uninstall, uninstallResult] = useUninstallMutation()

  const handleUninstall = () => {
    const yes = window.confirm('Are you sure to uninstall this app?')

    if (!yes) {
      return
    }

    const promise = uninstall().unwrap()
    toast.promise(promise, {
      loading: `Uninstalling ${title}`,
      success: `${title} integration uninstalled successfully`,
      error: parseErrorMessage(`Unable to uninstall ${title}. Try again`)
    })
  }

  const handleChangeAuthClick = () => {
    navigate('auth')
  }

  return (
    <Fragment>
      <InstallAppBase>
        <Heading level={2}>
          Successfully connected to {title} workspace. <br /> The account is tied to{' '}
          <Text isInline style='muted'>
            {app.account_url}
          </Text>
        </Heading>
        <Button variant='secondary' onClick={handleChangeAuthClick}>
          Reconnect
        </Button>
      </InstallAppBase>

      <SimpplrSites />

      <UnInstallApp onUninstall={handleUninstall} isLoading={uninstallResult.isLoading} />
    </Fragment>
  )
}

export default ManageSimpplr
