import { all } from 'redux-saga/effects'

const importAll = (r) => {
  const sagas = []
  r.keys().forEach((key) => {
    const saga = r(key).default

    if (saga) {
      // we might not have defined sagas in helper functions. ignore them
      sagas.push(saga())
    }
  })
  return sagas
}

export default function* rootSaga() {
  const sagas = importAll(require.context('./sagas', false, /\.js$/))

  yield all([...sagas])
}
