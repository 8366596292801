import { useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Heading, Button } from '@happyfoxinc/react-ui'
import Markdown from 'marked-react'

import styles from './AppDetails.module.css'
import BackIcon from 'Icons/back-arrow-circle.svg'

import NoRender from 'Components/NoRender'

import apps from '../apps-list'
import { openUrl } from 'Utils/url'

import AppDetailContext, { useAppDetailContext } from './AppDetailContext'
import HappyFoxHelpdesk from './apps/HappyFoxHelpdesk'
import Notion from './apps/Notion'
import Guru from './apps/Guru'
import Simpplr from './apps/Simpplr'
import Jira from './apps/Jira'
import Confluence from './apps/Confluence'
import SharePoint from './apps/SharePoint'

const DefaultComponent = () => 'Invalid App'

const componentList = {
  happyfox: HappyFoxHelpdesk,
  notion: Notion,
  guru: Guru,
  simpplr: Simpplr,
  jira_service_management: Jira,
  confluence: Confluence,
  sharepoint: SharePoint
}

const AppDetail = () => {
  const detail = useAppDetailContext()
  const Integration = componentList[detail.key] || DefaultComponent
  const Logo = detail.logo || NoRender

  return (
    <div className={styles.Container}>
      <div className={styles.SideContent}>
        <div className={styles.LogoContainer}>
          <Logo className={styles.BrandImage} />
        </div>
        <Heading level={2} className={styles.Title}>
          {detail.title}
        </Heading>
        <div className={styles.Description}>
          <Markdown>{detail.description}</Markdown>
        </div>
        {detail.kbLink && (
          <Button variant='secondary' onClick={() => openUrl(detail.kbLink)}>
            Detailed Guide
          </Button>
        )}
      </div>
      <div className={styles.MainContent}>
        <Integration />
      </div>
    </div>
  )
}

const AppDetails = () => {
  const { appName } = useParams()
  const navigate = useNavigate()

  const detail = useMemo(() => apps.find((app) => app.id === appName), [appName])

  const handleBackClick = () => {
    navigate('../')
  }

  if (!detail) {
    return <Navigate to='../' replace />
  }

  return (
    <AppDetailContext.Provider value={{ ...detail }}>
      <Heading className={styles.Title}>
        <BackIcon width='0.8em' height='0.8em' onClick={handleBackClick} />
        Apps
      </Heading>
      <AppDetail />
    </AppDetailContext.Provider>
  )
}

export default AppDetails
