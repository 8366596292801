import { useState } from 'react'
import { Heading, Text, Button, Small } from '@happyfoxinc/react-ui'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

import styles from './Dashboard.module.scss'
import TutImage from './tuts.svg'
import RightAngleIcon from 'Icons/arrow-right.svg'
import Check from 'Icons/check-green.svg'

import YoutubeEmbed from 'Components/YoutubeEmbed'
import PageLoader from 'Components/PageLoader'
import { usePlanContext } from 'Components/Plan'

import api from 'Services/api'
import { openUrl } from 'Utils/url'

const Card = ({ children }) => {
  return <div className={styles.Card}>{children}</div>
}

const CardContent = ({ title, connected = false, subtext, children }) => {
  return (
    <div className={styles.CardContent}>
      <Heading className={styles.CardTitle} level={2}>
        {title} {connected && <Check width='1em' height='1em' />}{' '}
        {subtext && (
          <Text className={styles.SubText} style='muted'>
            {subtext}
          </Text>
        )}
      </Heading>
      <Text style='muted'>{children}</Text>
    </div>
  )
}

const CardAction = ({ children }) => {
  return <div className={styles.CardAction}>{children}</div>
}

const CardGroup = ({ title, children }) => {
  return (
    <div className={styles.CardGroup}>
      <p className={styles.CardGroupTitle}>{title}</p>
      {children}
    </div>
  )
}

const ActionButton = ({ children, ...rest }) => {
  return (
    <Button variant='secondary' size='sm' className={styles.ActionButton} {...rest}>
      {children} <RightAngleIcon width='1em' height='1em' />
    </Button>
  )
}

const ActionConnectedButton = ({ ...rest }) => {
  return <Button variant='success' size='sm' className={styles.ActionConnectedButton} {...rest} />
}

const ConversationalTicketingAction = () => {
  const { isEnterprisePlan } = usePlanContext()

  if (isEnterprisePlan) {
    return null
  }

  return (
    <div className={styles.PlanLabel}>
      <Small style='muted'>Available on</Small>
      <Button
        variant='primary'
        size='xs'
        className={styles.PlanButton}
        onClick={() => openUrl('https://www.happyfox.com/assist-ai/')}
      >
        Enterprise Plan <RightAngleIcon width='1em' height='1em' />
      </Button>
    </div>
  )
}

const Dashboard = () => {
  const [showTutVideo, setShowTutVideo] = useState(false)

  const { isLoading, isFetching, data: insight = {} } = api.useGetDashboardQuery()
  const { data: user } = api.useGetProfileQuery()
  const navigate = useNavigate()
  const { planName, isProPlan, isEnterprisePlan } = usePlanContext()

  const getConnectedArticlesSubtext = () => {
    if (!insight.is_kb_integration_connected) {
      return ''
    }

    return `(${insight.indexed_kb_articles_count} articles synced)`
  }

  return (
    <div className={styles.Container}>
      <div className={cx(styles.Cover, { [styles.CoverPro]: isProPlan, [styles.CoverEnterprise]: isEnterprisePlan })}>
        <Heading className={styles.Heading}>Welcome</Heading>
        <Heading className={styles.Heading}>{user.name}!</Heading>

        <div className={styles.Pricing}>
          <span className={styles.PlanBadge}>{planName} Plan</span>
          {!isEnterprisePlan && (
            <div className={styles.PlanButtonContainer}>
              <Button
                className={styles.PlanComparisionButton}
                onClick={() => openUrl('https://www.happyfox.com/assist-ai/')}
              >
                Plan Comparison <RightAngleIcon width='1em' height='1em' />
              </Button>
              <Button
                className={styles.PlanComparisionButton}
                onClick={() => openUrl('https://www.happyfox.com/assist-ai/')}
              >
                Upgrade Plan <RightAngleIcon width='1em' height='1em' />
              </Button>
            </div>
          )}
        </div>

        <div className={styles.Tuts}>
          <Heading level={2} className={styles.Heading}>
            Getting started video
          </Heading>
          <TutImage className={styles.TutImage} onClick={() => setShowTutVideo(true)} />
          {showTutVideo && <YoutubeEmbed embedId='6tXRWfc5oHE' onClose={() => setShowTutVideo(false)} />}
        </div>
      </div>
      <div className={styles.Content}>
        <PageLoader isLoading={isLoading || isFetching}>
          <Heading className={styles.Heading}>
            Let's get Assist AI up <br /> and running!
          </Heading>

          <div className={styles.CardsContainer}>
            <Card>
              <CardContent
                title='Connect Knowledge Source'
                connected={insight.is_kb_integration_connected}
                subtext={getConnectedArticlesSubtext()}
              >
                Integrate your knowledge source with Assist AI to begin
                <br />
                answering questions.
              </CardContent>
              <CardAction>
                {insight.is_kb_integration_connected && (
                  <ActionConnectedButton onClick={() => navigate('/apps/category/knowledge-sources')}>
                    Connected
                  </ActionConnectedButton>
                )}
                {!insight.is_kb_integration_connected && (
                  <ActionButton onClick={() => navigate('/apps/category/knowledge-sources')}>Connect</ActionButton>
                )}
              </CardAction>
            </Card>

            <CardGroup title='Ticketing Systems'>
              <Card>
                <CardContent title='Connect External Ticketing System'>
                  Raise tickets from within the familiar Slack interface.
                </CardContent>
                <CardAction>
                  {insight.is_ticketing_integration_connected && (
                    <ActionConnectedButton onClick={() => navigate('/apps/category/ticketing-system')}>
                      Connected
                    </ActionConnectedButton>
                  )}
                  {!insight.is_ticketing_integration_connected && (
                    <ActionButton onClick={() => navigate('/apps/category/ticketing-system')}>Connect</ActionButton>
                  )}
                </CardAction>
              </Card>
              <Card>
                <CardContent title='Connect Conversational Ticketing'>
                  Add a human touch by integrating your conversational <br /> ticketing app to Assist AI.
                </CardContent>
                <CardAction>
                  <ConversationalTicketingAction />
                </CardAction>
              </Card>
            </CardGroup>

            <Card>
              <CardContent title='Let Your Team Know'>Announce Assist AI to your team and employees.</CardContent>
              <CardAction>
                <ActionButton
                  onClick={() =>
                    openUrl('https://support.happyfox.com/kb/article/1189-let-your-team-know-about-assist-ai/')
                  }
                >
                  Start
                </ActionButton>
              </CardAction>
            </Card>
          </div>
        </PageLoader>
      </div>
    </div>
  )
}

export default Dashboard
