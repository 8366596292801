import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'

import PageLoader from 'Components/PageLoader'

import AnswerForm from './AnswerForm'
import answerFormValidationSchema from './AnswerForm/answer-form-validation-schema'
import parseErrorMessage from 'Utils/error-message-parser'
import api from 'Services/api'

const AnswerFormHandler = (props) => {
  const { answer } = props
  const navigate = useNavigate()

  const formMethods = useForm({
    defaultValues: {
      name: answer.name,
      content: answer.content,
      visibility: answer.visibility,
      visibleToGroups: answer.visible_to_groups
    },
    resolver: yupResolver(answerFormValidationSchema)
  })
  const { handleSubmit } = formMethods

  const [updateAnswer, answerResult] = api.useUpdateAnswerMutation()
  const [deleteAnswer, deleteAnswerResult] = api.useDeleteAnswerMutation()

  const isLoading = answerResult.isLoading || deleteAnswerResult.isLoading

  const update = useCallback(
    (payload) => {
      const promise = updateAnswer(payload).unwrap()
      toast.promise(promise, {
        loading: 'Updating answer...',
        success: 'Answer updated successfully.',
        error: parseErrorMessage('Unable to update answer. Try again...')
      })
      return promise
    },
    [updateAnswer]
  )

  const processAnswer = useCallback(
    async (data, addMore = false) => {
      const payload = {
        id: answer.id,
        name: data.name,
        content: data.content
        // visibility: data.visibility
      }

      // if (data.visibleToGroups) {
      //   payload.visible_to_groups = data.visibleToGroups.map((group) => group.id)
      // }

      try {
        await update(payload)
        if (addMore) {
          navigate('../create')
        }
      } catch {}
    },
    [answer.id, update, navigate]
  )

  const onDeleteAnswer = useCallback(() => {
    const yes = window.confirm('Are you sure to delete this answer.')

    if (!yes) {
      return
    }

    const promise = deleteAnswer(answer.id).unwrap()

    toast.promise(promise, {
      loading: 'Deleting answer...',
      success: 'Answer deleted successfully.',
      error: parseErrorMessage('Unable to delete answer. Try again...')
    })

    promise.then(() => {
      navigate('../')
    })
  }, [answer.id, deleteAnswer, navigate])

  const onSubmit = useCallback(() => {
    handleSubmit((data) => processAnswer(data))()
  }, [handleSubmit, processAnswer])

  const onSaveAndAdd = useCallback(() => {
    handleSubmit((data) => processAnswer(data, true))()
  }, [handleSubmit, processAnswer])

  return (
    <FormProvider {...formMethods}>
      <AnswerForm
        onSubmit={onSubmit}
        onSaveAndAdd={onSaveAndAdd}
        onDeleteAnswer={onDeleteAnswer}
        isLoading={isLoading}
      />
    </FormProvider>
  )
}

const EditAnswer = () => {
  const { id } = useParams()
  const { isLoading, data: answer, isError, error } = api.useGetAnswerByIdQuery(id)

  if (isLoading) {
    return <PageLoader />
  }

  if (isError && error.originalStatus === 404) {
    return <Navigate to='../' replace />
  }

  return <AnswerFormHandler answer={answer} />
}

export default EditAnswer
