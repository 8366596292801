import { Route, Routes, useNavigate } from 'react-router-dom'

import PageLoader from 'Components/PageLoader'

import { useGetAppQuery } from './guru.service'

import InstallGuru from './InstallGuru'
import ManageGuru from './ManageGuru'
import GuruAuthForm from './GuruAuthForm'

const Guru = () => {
  const { isLoading, data: app = {} } = useGetAppQuery()
  const navigate = useNavigate()

  if (isLoading) {
    return <PageLoader />
  }

  if (!app.is_linked) {
    return <InstallGuru app={app} />
  }

  const handleAuthFormCancel = () => {
    navigate('./')
  }

  return (
    <Routes>
      <Route
        path='auth'
        element={<GuruAuthForm onCancel={handleAuthFormCancel} onAuth={handleAuthFormCancel} app={app} />}
      />
      <Route path='*' element={<ManageGuru app={app} />} />
    </Routes>
  )
}

export default Guru
