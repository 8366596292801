import { Heading, Button } from '@happyfoxinc/react-ui'

import styles from './CrashPage.module.css'
import CrashIcon from './crash.svg'

const CrashPage = () => {
  const handleButtonClick = () => {
    window.location.reload()
  }

  return (
    <div className={styles.GridContainer}>
      <div className={styles.Container}>
        <CrashIcon />
        <Heading>Something went wrong while displaying this page</Heading>
        <Button variant='primary' onClick={handleButtonClick}>
          Reload this page
        </Button>
      </div>
    </div>
  )
}

export default CrashPage
