import { useState, useMemo } from 'react'
import { CheckboxTree, Heading, Button } from '@happyfoxinc/react-ui'
import { components } from 'react-select'
import toast from 'react-hot-toast'

import styles from './NotionSelectPages.module.scss'
import SearchIcon from 'Icons/lens.svg'

import { useGetAllNotionPagesQuery, useLazySearchNotionPagesQuery, useSyncNotionPagesMutation } from '../notion.service'
import PageLoader from 'Components/PageLoader'
import ReactSelect from 'Components/ReactSelect'

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  )
}

const NotionSelectPages = () => {
  const { data: pages = [], isLoading, isFetching } = useGetAllNotionPagesQuery()
  const [searchPage] = useLazySearchNotionPagesQuery()
  const [syncPages, syncPagesResult] = useSyncNotionPagesMutation()

  const [customPages, setCustomPagesPages] = useState([])
  const [selectedPages, setSelectedPages] = useState([])
  const [selectedSearchPage, setSelectedSearchPage] = useState(null)

  const allPages = useMemo(() => {
    const mergedPages = [...pages, ...customPages]

    return Array.from(
      // remove duplicates
      mergedPages
        .reduce((map, page) => {
          map.set(page.id, page)
          return map
        }, new Map())
        .values()
    )
  }, [pages, customPages])

  const tree = useMemo(() => {
    if (!allPages.length) {
      return []
    }

    return [
      {
        id: 0,
        name: 'Pages',
        children: allPages.map((page) => {
          return {
            ...page,
            name: page.title,
            parentId: 0,
            children: []
          }
        })
      }
    ]
  }, [allPages])

  const handleSelection = (selectedNodes) => {
    setSelectedPages(selectedNodes)
  }

  const handleSearchSelection = (selected) => {
    const selectedValueExists = pages.find((page) => page.id === selected.id)

    if (!selectedValueExists) {
      setCustomPagesPages((previousState) => [...previousState, selected])
    }

    setSelectedSearchPage(selected)
    setSelectedPages((previousSelectedPages) => Array.from(new Set([...previousSelectedPages, selected.id])))
  }

  const loadOptions = (inputValue, cb) => {
    searchPage(inputValue).unwrap().then(cb)
  }

  const handleSync = async () => {
    const pageIdsToSync = selectedPages.filter((pageId) => pageId !== 0)

    const payload = {
      notion_page_ids: pageIdsToSync
    }

    try {
      const promise = syncPages(payload).unwrap()

      toast.promise(promise, {
        loading: 'Syncing selected notion pages',
        success: 'Sucessfully synced pages',
        error: 'Unable to sync pages'
      })
      await promise
      setSelectedPages([])
    } catch {}
  }

  return (
    <div className={styles.Container}>
      <Heading level={2}>Select Notion page(s) to sync from</Heading>
      <PageLoader isLoading={isLoading || isFetching}>
        <ReactSelect
          value={selectedSearchPage}
          onChange={handleSearchSelection}
          loadOptions={loadOptions}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.title}
          noOptionsMessage={() => 'No Pages found'}
          loadingMessage={() => 'Searching pages'}
          placeholder='Search for pages in your notion'
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: '#EEEEEE',
              border: 0
            })
          }}
          components={{
            DropdownIndicator
          }}
        />
        <div className={styles.CheckboxTreeContainer}>
          <CheckboxTree nodes={tree} checked={selectedPages} onChange={handleSelection} />
          <div className={styles.SyncButtonContainer}>
            <Button size='sm' variant='secondary' onClick={handleSync} disabled={syncPagesResult.isLoading}>
              Sync now
            </Button>
          </div>
        </div>
      </PageLoader>
    </div>
  )
}

export default NotionSelectPages
