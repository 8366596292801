import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import queryString from 'query-string'

import { redirect } from 'Utils/url'
import { getCookie } from 'Utils/cookie'

const paramsSerializer = (params) => {
  return queryString.stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true
  })
}

const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  credentials: 'same-origin',
  paramsSerializer,
  prepareHeaders: (headers) => {
    const csrftoken = getCookie('csrftoken')
    if (csrftoken) {
      headers.set('X-CSRFToken', csrftoken)
    }
    return headers
  }
})

export const rtkBaseQuery = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    // // try to get a new token
    // const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
    // if (refreshResult.data) {
    //   // store the new token
    //   api.dispatch(tokenReceived(refreshResult.data))
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions)
    // } else {
    //   api.dispatch(loggedOut())
    //   // or
    //   api.dispatch(unAuthorized())
    // }
    redirect('logout')
  }

  return result
}
