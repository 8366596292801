import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import { InstallAppBase } from '../../../components/InstallApp'
import NotionAccounts from './NotionAccounts'
import NotionPages from './NotionPages'
import NotionSelectPages from './NotionSelectPages'

const ManageNotion = (props) => {
  const { app } = props

  return (
    <Fragment>
      <InstallAppBase>
        <Heading level={2}>
          Successfully connected to {app.connected_workspace} workspace. <br /> The account is tied to{' '}
          <Text isInline style='muted'>
            {app.user_email_id}
          </Text>
        </Heading>
      </InstallAppBase>
      {app.is_linked_by_other_users && <NotionAccounts />}
      {(app.is_linked || app.is_linked_by_other_users) && <NotionPages />}
      <NotionSelectPages />
    </Fragment>
  )
}

export default ManageNotion
