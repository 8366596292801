import { Fragment } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Heading, Label, Input, Link, FormControl, FormHelpText, FormErrorText, Button } from '@happyfoxinc/react-ui'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'

import styles from './ManageHappyFoxHelpdesk.module.css'

import ReactSelect from 'Components/ReactSelect'

import { useAppDetailContext } from '../../AppDetailContext'
import { useUninstallMutation, useUpdateMutation } from './happyfox-helpdesk.service'
import UnInstallApp from '../../../components/UnInstallApp'
import happyfoxHelpdeskSettingsFormValidationSchema from './happyfox-helpdesk-settings-form-validation-schema'
import parseErrorMessage from 'Utils/error-message-parser'

const ManageHappyFoxHelpdesk = (props) => {
  const { app } = props

  const { title } = useAppDetailContext()
  const navigate = useNavigate()

  const [uninstall, uninstallResult] = useUninstallMutation()
  const [updateSettings, updateSettingsResult] = useUpdateMutation()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      ticketSubject: app.subject ?? '{{message}}',
      allowedCategories: app.allowed_categories.map((categoryId) =>
        app.all_categories.find((category) => category.id === categoryId)
      ),
      solvedTicketStatus: app.default_solved_status
    },
    resolver: yupResolver(happyfoxHelpdeskSettingsFormValidationSchema)
  })

  const handleFormSubmit = (data) => {
    const payload = {
      ticket_subject: data.ticketSubject,
      allowed_categories: data.allowedCategories.map((category) => category.id),
      default_solved_status: data.solvedTicketStatus
    }

    const promise = updateSettings(payload).unwrap()

    toast.promise(promise, {
      loading: `Updating settings for ${title}`,
      success: `Sucessfully updated settings for ${title}`,
      error: parseErrorMessage(`Unable to update ${title} settings. Try again`)
    })
  }

  const handleUninstall = () => {
    const yes = window.confirm('Are you sure to uninstall this app?')

    if (!yes) {
      return
    }

    const promise = uninstall().unwrap()
    toast.promise(promise, {
      loading: `Uninstalling ${title}`,
      success: `${title} integration uninstalled successfully`,
      error: `Unable to uninstall ${title}. Try again`
    })
  }

  const handleChangeAuthClick = () => {
    navigate('auth')
  }

  return (
    <Fragment>
      <Heading level={2}>Configuration {title}</Heading>

      <section>
        <FormControl>
          <Label className={styles.AccountLabel}>
            You have successfully connected your HappyFox Help Desk account
            <Link role='button' className={styles.ChangeButton} onClick={handleChangeAuthClick}>
              Change
            </Link>
          </Label>
          <Input value={app.account_url} readOnly />
          <FormHelpText>Example: https://myaccount.helpdesk.com</FormHelpText>
        </FormControl>
      </section>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormControl>
          <Label>
            Ticket Subject <sup>*</sup>
          </Label>
          <Input {...register('ticketSubject')} />
          {errors.ticketSubject && <FormErrorText>{errors.ticketSubject.message}</FormErrorText>}
        </FormControl>

        <FormControl>
          <Label>
            Allowed categories <sup>*</sup>
          </Label>
          <Controller
            control={control}
            name='allowedCategories'
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  isMulti
                  isClearable={false}
                  options={app.all_categories}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              )
            }}
          />
          {errors.allowedCategories && <FormErrorText>{errors.allowedCategories.message}</FormErrorText>}
        </FormControl>

        <FormControl className={styles.Status}>
          <Label>
            Status for solved tickets <sup>*</sup>
          </Label>
          <Controller
            control={control}
            name='solvedTicketStatus'
            render={({ field }) => {
              return (
                <ReactSelect
                  {...field}
                  options={app.all_status}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              )
            }}
          />
          {errors.solvedTicketStatus && <FormErrorText>{errors.solvedTicketStatus.message}</FormErrorText>}
        </FormControl>

        <Button type='submit' variant='primary' className={styles.SaveButton} disabled={updateSettingsResult.isLoading}>
          Save
        </Button>
      </form>

      <UnInstallApp onUninstall={handleUninstall} isLoading={uninstallResult.isLoading} />
    </Fragment>
  )
}

export default ManageHappyFoxHelpdesk
