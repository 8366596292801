import { forwardRef } from 'react'
import 'trix'
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from 'react-trix-rte'
import cx from 'classnames'
import { useFormControl } from '@happyfoxinc/react-ui'

import styles from './TrixEditor.module.scss'

const toolbarItems = [
  'bold',
  'italic',
  'strike',
  'link',
  'heading1',
  'quote',
  'code',
  'bullet',
  'number',
  'undo',
  'redo'
]

const TrixEditor = forwardRef((props, ref) => {
  const { isInvalid } = useFormControl()
  const { defaultValue, ...rest } = props

  const handleChange = (_event, newValue) => {
    props.onChange(newValue)
  }

  const handleAddAttachment = (event) => {
    props.onImageUpload(event)
  }

  return (
    <div className={styles.TrixContainer}>
      <ReactTrixRTEToolbar toolbarId='trix-editor' toolbarActions={toolbarItems} />
      <ReactTrixRTEInput
        toolbarId='trix-editor'
        defaultValue={defaultValue}
        onChange={handleChange}
        onAttachmentAdd={handleAddAttachment}
        className={cx(styles.TrixContent, { [styles.Invalid]: isInvalid })}
        trixInputRef={ref}
        {...rest}
      />
    </div>
  )
})

TrixEditor.displayName = 'TrixEditor'

export default TrixEditor
