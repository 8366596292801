import { createApi } from '@reduxjs/toolkit/query/react'

import { rtkBaseQuery } from '../../../../../../api'

export const notionApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'notionApi',
  tagTypes: ['Notion', 'NotionAccounts', 'NotionPages', 'NotionSyncedPages'],
  refetchOnMountOrArgChange: 5,
  endpoints: (builder) => ({
    getApp: builder.query({
      query: () => '/v1/apps/notion',
      providesTags: ['Notion']
    }),
    uninstall: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/notion',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Notion']
    }),
    getNotionUserAccounts: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/notion/accounts',
          method: 'GET',
          params
        }
      },
      providesTags: ['NotionAccounts']
    }),
    getNotionSyncedPages: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/notion/pages/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['NotionSyncedPages']
    }),
    removeNotionPage: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/apps/notion/pages/sync/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['NotionSyncedPages', 'NotionPages']
    }),
    getAllNotionPages: builder.query({
      query: () => `/v1/apps/notion/pages`,
      providesTags: ['NotionPages']
    }),
    searchNotionPages: builder.query({
      query: (title = '') => {
        return {
          url: `/v1/apps/notion/pages`,
          method: 'GET',
          params: {
            page_title: title
          }
        }
      },
      providesTags: ['NotionPages']
    }),
    syncNotionPages: builder.mutation({
      query: (data) => {
        return {
          url: `/v1/apps/notion/pages/sync`,
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['NotionPages', 'NotionSyncedPages']
    })
  })
})

export const {
  useGetAppQuery,
  useUninstallMutation,
  useGetNotionUserAccountsQuery,
  useGetNotionSyncedPagesQuery,
  useRemoveNotionPageMutation,
  useGetAllNotionPagesQuery,
  useLazySearchNotionPagesQuery,
  useSyncNotionPagesMutation
} = notionApi

export default notionApi
