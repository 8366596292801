import { Button } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'

import InviteAgentModal from './InviteAgentModal'

const InviteAgent = () => {
  const [showModal, setShowModal] = useState(false)

  const handleInviteAgentClick = () => {
    setShowModal(true)
  }

  const handleHideModal = () => {
    setShowModal(false)
  }

  return (
    <Fragment>
      {showModal && <InviteAgentModal onHide={handleHideModal} />}
      <Button variant='primary' onClick={handleInviteAgentClick}>
        Invite Admin
      </Button>
    </Fragment>
  )
}

export default InviteAgent
