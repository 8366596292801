import { NavLink, Outlet } from 'react-router-dom'
import cx from 'classnames'

import styles from './VerticalTabs.module.css'
import ArrowRight from 'Icons/arrow-right.svg'

import NoRender from 'Components/NoRender'

const VerticalTabs = (props) => {
  const { tabs = [], sidebarContent = NoRender } = props

  return (
    <div className={styles.Container}>
      <div className={styles.Sidebar}>
        <nav className={styles.Nav}>
          {tabs.map((tab, index) => {
            return (
              <NavLink
                title={tab.label}
                key={`App__Link__${tabs.path}-${index}`}
                to={tab.path}
                className={({ isActive }) => cx(styles.NavLink, { [styles.NavLinkActive]: isActive })}
              >
                {({ isActive }) => {
                  return (
                    <span className={styles.NavItem}>
                      {tab.label} {isActive && <ArrowRight />}
                    </span>
                  )
                }}
              </NavLink>
            )
          })}
        </nav>
        <div className={styles.SidebarContent}>{sidebarContent}</div>
      </div>
      <div className={styles.Content}>
        <Outlet />
      </div>
    </div>
  )
}

export default VerticalTabs
