import { forwardRef, useMemo } from 'react'
import Select from 'react-select'
import SelectCreatable from 'react-select/creatable'
import SelectAsync from 'react-select/async'
import cx from 'classnames'

import styles from './ReactSelect.module.css'

const NoRender = () => null

const ReactSelect = forwardRef((props, ref) => {
  const { className, isInvalid = false, creatable = false, ...rest } = props

  const SelectComponent = useMemo(() => {
    if (rest.loadOptions) {
      return SelectAsync
    }

    return creatable ? SelectCreatable : Select
  }, [creatable, rest.loadOptions])

  const components = useMemo(() => {
    const selectComponents = {
      ...rest.components,
      IndicatorSeparator: null
    }

    Object.entries(selectComponents).forEach(([key, value]) => {
      if (!value) {
        selectComponents[key] = NoRender
      }
    })

    return selectComponents
  }, [rest.components])

  return (
    <SelectComponent
      {...rest}
      className={cx(styles.Select, className)}
      ref={ref}
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'var(--primary-color)',
            primary25: 'var(--primary-color-10)'
          }
        }
      }}
      components={components}
      styles={{
        ...rest.styles,
        control: (provided) => ({
          ...provided,
          boxShadow: 'none',
          ...(isInvalid ? { borderColor: 'var(--danger-color)' } : {}),
          ...(rest.styles?.control ? rest.styles.control(provided) : {})
        }),
        multiValue: (provided) => ({
          ...provided,
          backgroundColor: `var(--primary-color-10)`,
          border: `1px solid var(--primary-color-20)`,
          borderRadius: '4px',
          ...(rest.styles?.multiValue ? rest.styles.multiValue(provided) : {})
        })
      }}
      classNamePrefix='ReactSelectExtended'
    />
  )
})

ReactSelect.displayName = 'ReactSelect'

export default ReactSelect
