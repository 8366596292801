import { createApi } from '@reduxjs/toolkit/query/react'

import { rtkBaseQuery } from '../../../../../../api'

export const confluenceApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'confluenceApi',
  tagTypes: ['Confluence'],
  refetchOnMountOrArgChange: 5,
  endpoints: (builder) => ({
    getApp: builder.query({
      query: () => '/v1/apps/confluence',
      providesTags: ['Confluence']
    }),
    install: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/confluence',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Confluence']
    }),
    uninstall: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/confluence',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Confluence']
    })
  })
})

export const { useGetAppQuery, useInstallMutation, useUninstallMutation } = confluenceApi

export default confluenceApi
