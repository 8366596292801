export const FEATURE_FLAGS = {
  USER_GROUPS: 'user_groups',
  CONVERSATIONAL_TICKETING: 'conversational_ticketing',
  PREMIUM_INTEGRATIONS: 'premium_integrations'
}

// Enabled prefix represents computed property from boolean flag
// may not sound well for a feature flag, but 🤷
export const CLIENT_FEATURE_FLAGS = {
  ENABLE_TICKETING: 'enable_ticketing',
  DM_WORKFLOW_MODE: 'dm_workflow_mode'
}

export default FEATURE_FLAGS
