import ReactModal from 'react-modal'

import styles from './Modal.module.css'
import CloseIcon from 'Icons/close.svg'

export const ModalHeader = ({ children, onClose }) => {
  return (
    <div className={styles.Header}>
      <div className={styles.HeaderContent}>{children}</div>
      <div className={styles.Close} onClick={onClose}>
        <CloseIcon width='18px' height='16px' />
      </div>
    </div>
  )
}

export const ModalBody = ({ children }) => {
  return <div className={styles.ModalBody}>{children}</div>
}

const Modal = (props) => {
  return <ReactModal parentSelector={() => document.getElementById('__modal-root')} {...props} />
}

export default Modal
