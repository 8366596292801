import * as yup from 'yup'

const happyfoxHelpdeskSettingsFormValidationSchema = yup
  .object()
  .shape({
    ticketSubject: yup.string().trim().required('Ticket subject is required'),
    allowedCategories: yup.array().min(1, 'Allowed categories is required'),
    solvedTicketStatus: yup.mixed().required('Solved ticket status is required')
  })
  .required()

export default happyfoxHelpdeskSettingsFormValidationSchema
