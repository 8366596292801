import * as yup from 'yup'

const answerFormValidationSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').min(3, 'Name should contain at least ${min} characters'),
    content: yup.string().trim().required('Content is required')
    // visibility: yup.string().optional(),
    // visibleToGroups: yup.array().when('visibility', {
    //   is: 'specificGroups',
    //   then: yup.array().min(1, 'Please select atleast 1 User group').required()
    // })
  })
  .required()

export default answerFormValidationSchema
