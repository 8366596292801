import styles from './PageLoader.module.scss'

const PageLoader = ({ isLoading = true, children }) => {
  if (isLoading) {
    return (
      <div className={styles.Container}>
        <div className={styles.Loader} />
      </div>
    )
  }

  return children
}

export default PageLoader
