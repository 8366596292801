import cx from 'classnames'
import { Input } from '@happyfoxinc/react-ui'

import styles from './SearchInput.module.css'
import LensIcon from 'Icons/lens.svg'

const SearchInput = (props) => {
  const { className, ...rest } = props

  return (
    <div className={styles.InputContainer}>
      <LensIcon width='1em' height='1em' className={styles.Lens} />
      <Input className={cx(styles.Search, className)} {...rest} />
    </div>
  )
}

export default SearchInput
