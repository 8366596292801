import * as yup from 'yup'

const jiraAuthFormValidationSchema = yup
  .object()
  .shape({
    accountUrl: yup.string().trim().required('Account URL is required').url('Please enter a valid URL'),
    installCode: yup.string().trim().required('Installation code is required')
  })
  .required()

export default jiraAuthFormValidationSchema
