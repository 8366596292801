import { useNavigate } from 'react-router-dom'

import styles from './AppTiles.module.scss'
import Check from 'Icons/check-green-circle.svg'

const NoRender = () => null

const AppTile = (props) => {
  const navigate = useNavigate()
  const { title, id, route, logo, status, planCategory } = props

  const Icon = logo || NoRender
  const to = '/apps/' + (route || id)

  const isLinked = status === 'linked'
  const showPremiumBadge = !isLinked && planCategory === 'premium'

  return (
    <div className={styles.Tile} onClick={() => navigate(to)}>
      <div className={styles.LogoContainer}>
        <Icon height='30' width='30' />
      </div>
      {title}
      {isLinked && (
        <div className={styles.Status}>
          <Check height='16px' width='16px' />
        </div>
      )}
      {showPremiumBadge && <div className={styles.PremiumBadge}>Premium</div>}
    </div>
  )
}

const AppTiles = ({ apps }) => {
  return (
    <div className={styles.TilesContainer}>
      {apps.map((app) => {
        return <AppTile {...app} key={`App_Tile__${app.key}`} />
      })}
    </div>
  )
}

export default AppTiles
