import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'

import AnswerForm from './AnswerForm'
import answerFormValidationSchema from './AnswerForm/answer-form-validation-schema'
import parseErrorMessage from 'Utils/error-message-parser'
import api from 'Services/api'

const CreateAnswer = () => {
  const navigate = useNavigate()

  const formMethods = useForm({
    defaultValues: {
      name: '',
      content: '',
      visibility: 'everyone',
      visibleToGroups: []
    },
    resolver: yupResolver(answerFormValidationSchema)
  })
  const { handleSubmit, reset } = formMethods

  const [createAnswer, answerResult] = api.useCreateAnswerMutation()

  const processAnswer = useCallback(
    async (data, addMore = false) => {
      const payload = {
        name: data.name,
        content: data.content,
        visibility: data.visibility,
        visible_to_groups: data.visible_to_groups
      }

      try {
        const promise = createAnswer(payload).unwrap()

        toast.promise(promise, {
          loading: 'Creating answer',
          success: 'Answer created successfully',
          error: parseErrorMessage('Unable to create answer. Try again...')
        })

        const answer = await promise

        if (addMore) {
          reset(undefined, { keepErrors: false })
        }
        navigate('../' + `${addMore ? 'create' : answer.id}`, { replace: true })
      } catch {}
    },
    [createAnswer, navigate, reset]
  )

  const onSubmit = useCallback(() => {
    handleSubmit((data) => processAnswer(data))()
  }, [handleSubmit, processAnswer])

  const onSaveAndAdd = useCallback(() => {
    handleSubmit((data) => processAnswer(data, true))()
  }, [handleSubmit, processAnswer])

  return (
    <FormProvider {...formMethods}>
      <AnswerForm onSubmit={onSubmit} onSaveAndAdd={onSaveAndAdd} isLoading={answerResult.isLoading} />
    </FormProvider>
  )
}

export default CreateAnswer
