import dayjs from 'dayjs'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import utcPlugin from 'dayjs/plugin/utc'

dayjs.extend(relativeTimePlugin)
dayjs.extend(utcPlugin)

export const toUtc = (date) => {
  return dayjs(date).utc(date)
}

export const ago = (date, noSuffix = false, utc = false) => {
  const d = utc ? toUtc(date) : dayjs(date)
  return d.fromNow(noSuffix)
}

export const isValid = (date) => {
  return dayjs(date).isValid()
}

export const format = (date, template) => {
  return dayjs(date).format(template)
}
