import { createApi } from '@reduxjs/toolkit/query/react'

import { rtkBaseQuery } from '../../../../../../api'

export const simpplrApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'simpplrApi',
  tagTypes: ['Simpplr', 'SimpplrSites'],
  refetchOnMountOrArgChange: 5,
  endpoints: (builder) => ({
    getApp: builder.query({
      query: () => '/v1/apps/simpplr',
      providesTags: ['Simpplr']
    }),
    install: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/simpplr/authorize',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Simpplr']
    }),
    uninstall: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/simpplr',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Simpplr']
    }),
    getSites: builder.query({
      query: (params) => {
        return {
          url: '/v1/apps/simpplr/sites/sync',
          method: 'GET',
          params
        }
      },
      providesTags: ['SimpplrSites']
    }),
    addSimpplrSite: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/apps/simpplr/sites/sync`,
          method: 'POST',
          body: {
            site_id: id
          }
        }
      },
      invalidatesTags: ['SimpplrSites']
    }),
    removeSimpplrSite: builder.mutation({
      query: (id) => {
        return {
          url: `/v1/apps/simpplr/sites/sync/${id}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: ['SimpplrSitess']
    }),
    searchSites: builder.query({
      query: (siteName) => {
        return {
          url: `/v1/apps/simpplr/sites`,
          method: 'GET',
          params: {
            site_name: siteName
          }
        }
      }
    })
  })
})

export const {
  useGetAppQuery,
  useInstallMutation,
  useUninstallMutation,
  useGetSitesQuery,
  useRemoveSimpplrSiteMutation,
  useLazySearchSitesQuery,
  useAddSimpplrSiteMutation
} = simpplrApi

export default simpplrApi
