import { Fragment } from 'react'

import PageLoader from 'Components/PageLoader'

import { useGetAppQuery } from './notion.service'
import InstallNotion from './InstallNotion'
import ManageNotion from './ManageNotion'

const Notion = () => {
  const { isLoading, data: app = {} } = useGetAppQuery()

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Fragment>
      {!app.is_linked && <InstallNotion app={app} />}
      {app.is_linked && <ManageNotion app={app} />}
    </Fragment>
  )
}

export default Notion
