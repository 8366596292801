import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Table,
  TableActions,
  TableBody,
  TableHead,
  TableRow,
  Td,
  Th,
  PaginationControls,
  Heading,
  Text,
  Button
} from '@happyfoxinc/react-ui'

import styles from './AnswersList.module.css'

import SearchInput from 'Components/SearchInput'
import PageLoader from 'Components/PageLoader'

import debounce from 'Utils/debounce'
import * as date from 'Utils/date'

import NoAnswers from './NoAnswers'
import AnswerOptions from './AnswerOptions'
import api from 'Services/api'

const DEFAULT_PAGE_SIZE = 15
const DEFAULT_PAGE = 1
const SUPPORTED_SORT_COLUMNS = ['created_at', 'updated_at']
const DEFAULT_SORTED_COLUMN = 'created_at'
const DEFAULT_SORT_ORDER = 'desc'
const SUPPORTED_SORT_ORDER = ['asc', 'desc']

const getDefaultParams = () => {
  const params = new URLSearchParams(location.search)

  const paramsSize = params.get('size') || DEFAULT_PAGE_SIZE
  const paramsPage = params.get('page') || DEFAULT_PAGE
  const paramsSearch = params.get('search') ?? ''
  const paramsSortBy = params.get('sort_by') ?? DEFAULT_SORTED_COLUMN
  const paramsOrderBy = params.get('order_by') ?? DEFAULT_SORT_ORDER

  const page = isNaN(paramsPage) ? DEFAULT_PAGE : parseInt(paramsPage)
  const size = isNaN(paramsSize) ? DEFAULT_PAGE_SIZE : parseInt(paramsSize)

  const sortColumn = SUPPORTED_SORT_COLUMNS.includes(paramsSortBy) ? paramsSortBy : DEFAULT_SORTED_COLUMN
  const sortOrder = SUPPORTED_SORT_ORDER.includes(paramsOrderBy) ? paramsOrderBy : DEFAULT_SORT_ORDER

  return {
    search: paramsSearch,
    page,
    size,
    sortBy: [{ id: sortColumn, desc: sortOrder === 'desc' }]
  }
}

const getInitialApiSearchParams = (params) => {
  return {
    offset: (params.page - 1) * params.size,
    limit: params.size,
    sort_by: params.sortBy[0].id,
    order_by: params.sortBy[0].desc ? 'desc' : 'asc',
    search: params.search
  }
}

const AnswersTable = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  const initialParams = useMemo(getDefaultParams, [])

  const [queryParams, setQueryParams] = useState(getInitialApiSearchParams(initialParams))
  const [searchText, setSearchText] = useState(initialParams.search)
  const [pageSize] = useState(initialParams.size)

  const { data = {}, isLoading } = api.useListAnswersQuery(queryParams)
  const { results: answers = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        disableSortBy: false,
        Cell: ({ cell: { value } }) => {
          return date.ago(value)
        }
      },
      {
        Header: 'Last Update',
        disableSortBy: false,
        accessor: 'updated_at',
        Cell: ({ cell: { value } }) => {
          return value ? date.ago(value) : '-'
        }
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex, sortBy }
  } = useTable(
    {
      columns,
      data: answers,
      initialState: {
        pageIndex: initialParams.page - 1,
        pageSize,
        sortBy: initialParams.sortBy
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / pageSize),
      // sort
      disableSortRemove: true,
      manualSortBy: true,
      autoResetSortBy: false
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    const params = {
      sort_by: '',
      order_by: '',
      search: searchText
    }

    sortBy.forEach((sort) => {
      params.sort_by = sort.id
      params.order_by = sort.desc ? 'desc' : 'asc'
    })

    const apiParams = {
      offset: pageIndex * pageSize,
      limit: pageSize,
      ...params
    }

    const urlPrams = {
      page: pageIndex + 1,
      size: pageSize,
      ...params
    }

    debouncedSetQueryParams(apiParams)
    setSearchParams(urlPrams, { replace: true })
  }, [pageIndex, pageSize, searchText, sortBy, debouncedSetQueryParams, setSearchParams])

  const handleSearchInputChange = (e) => {
    gotoPage(0)
    setSearchText(e.target.value)
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <div className={styles.Container}>
      <TableActions>
        <div className={styles.SearchInputContainer}>
          <SearchInput placeholder='Search answers' value={searchText} onChange={handleSearchInputChange} />
        </div>
        <PaginationControls
          currentPage={pageIndex + 1}
          pageSize={pageSize}
          totalItems={paginationDetails.total}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
        />
        <Button variant='primary' onClick={() => navigate('create')}>
          New Answer
        </Button>
      </TableActions>
      <Table className={styles.AnswerTable} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps())

                    return (
                      <Th key={key} isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} {...headerProps}>
                        {column.render('Header')}
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()
            const answer = row.original

            return (
              <TableRow key={key} {...rowProps} clickable>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()
                  return (
                    <Td key={key} {...cellProps} onClick={() => navigate(answer.id)}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
                <Td>
                  <AnswerOptions id={answer.id} />
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {rows.length === 0 && <div className={styles.NoFilterResults}>No results found for given query</div>}
    </div>
  )
}

const AnswersList = () => {
  const { data = {}, isLoading } = api.useListAnswersQuery()

  if (isLoading) {
    return <PageLoader />
  }

  if (data.results?.length === 0) {
    return <NoAnswers />
  }

  return (
    <Fragment>
      <Heading>Answers</Heading>
      <Text style='muted'>
        Answers let you create and manage all commonly asked questions within Assist AI. Answers complement other
        existing knowledge sources.
      </Text>
      <AnswersTable />
    </Fragment>
  )
}

export default AnswersList
