import { useState } from 'react'

import { useAppDetailContext } from '../../AppDetailContext'
import InstallApp from '../../../components/InstallApp'

import { externalRedirect } from 'Utils/url'

const InstallNotion = (props) => {
  const [isInstalling, setIsInstalling] = useState()
  const { app } = props
  const { title } = useAppDetailContext()

  const handleInstall = () => {
    setIsInstalling(true)

    const redirected = externalRedirect(app.authorize_url)
    if (redirected === false) {
      setIsInstalling(false)
    }
  }

  return <InstallApp name={title} onInstall={handleInstall} isInstalling={isInstalling} />
}

export default InstallNotion
