import { Fragment, useMemo, useState, useEffect } from 'react'
import { Heading, TableActions, PaginationControls, Th, Link, Text, Button, FormErrorText } from '@happyfoxinc/react-ui'
import { useTable, usePagination } from 'react-table'
import { Controller, useForm } from 'react-hook-form'

import styles from './SharePointSites.module.scss'
import ExternalLinkIcon from 'Icons/external-link.svg'

import { useAddSharePointSiteMutation, useGetSitesQuery, useLazySearchSitesQuery } from '../sharepoint.service'
import PageLoader from 'Components/PageLoader'
import debounce from 'Utils/debounce'
import SharePointSitesOptions from './SharePointSitesOptions'
import ReactSelect from 'Components/ReactSelect'

const PAGE_SIZE = 5
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const SharePointSites = () => {
  const [queryParams, setQueryParams] = useState(defaultApiParams)

  const { isLoading, data = {} } = useGetSitesQuery(queryParams)
  const { results: sites = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Site Name',
        accessor: (originalRow) => originalRow,
        disableSortBy: true,
        Cell: ({ cell: { value: site } }) => {
          return (
            <Link href={site.site_url} target='_blank' className={styles.SiteLink} rel='noreferrer'>
              {site.site_name} <ExternalLinkIcon width='1em' height='1em' />
            </Link>
          )
        }
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: sites,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {}

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams])

  const [searchSites] = useLazySearchSitesQuery()
  const [addSite, addSiteResult] = useAddSharePointSiteMutation()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      site: null
    }
  })

  const handleSiteAddition = async (data) => {
    const { site } = data
    try {
      await addSite(site.SiteId)
      reset()
    } catch {}
  }

  const loadOptions = (inputValue, cb) => {
    searchSites(inputValue).unwrap().then(cb)
  }

  return (
    <Fragment>
      <div className={styles.AddSiteContainer}>
        <Heading level={2}>Add a SharePoint site to sync from</Heading>
        <form onSubmit={handleSubmit(handleSiteAddition)} className={styles.AddSiteForm}>
          <Controller
            control={control}
            name='site'
            rules={{ required: 'Search and select a site to add' }}
            render={({ field }) => {
              return (
                <ReactSelect
                  className={styles.SearchSite}
                  {...field}
                  loadingMessage={() => 'Searching sites'}
                  loadOptions={loadOptions}
                  getOptionLabel={(option) => option.Title}
                  getOptionValue={(option) => option.Path}
                />
              )
            }}
          />
          <Button type='submit' variant='secondary' disabled={addSiteResult.isLoading}>
            + Add
          </Button>
        </form>
        {errors.site && <FormErrorText>{errors.site.message}</FormErrorText>}
      </div>
      <PageLoader isLoading={isLoading}>
        <div>
          <Heading level={2}>Sites currently syncing from</Heading>
          <div className={styles.TableContainer}>
            <TableActions>
              <PaginationControls
                currentPage={pageIndex + 1}
                pageSize={PAGE_SIZE}
                totalItems={paginationDetails.total}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                nextPage={nextPage}
              />
            </TableActions>
            <table className={styles.Table} {...getTableProps()}>
              <thead className={styles.Th}>
                {headerGroups.map((headerGroup) => {
                  const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

                  return (
                    <tr key={key} {...headerGroupProps}>
                      <Fragment>
                        {headerGroup.headers.map((column) => {
                          const { key, ...headerProps } = column.getHeaderProps()

                          return (
                            <th className={styles.Th} key={key} {...headerProps}>
                              {column.render('Header')}
                            </th>
                          )
                        })}
                        <Th isOption />
                      </Fragment>
                    </tr>
                  )
                })}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)
                  const { key, ...rowProps } = row.getRowProps()
                  const site = row.original

                  return (
                    <tr key={key} {...rowProps}>
                      <Fragment>
                        {row.cells.map((cell) => {
                          const { key, ...cellProps } = cell.getCellProps()
                          return (
                            <td className={styles.Td} key={key} {...cellProps}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                        <td>
                          <SharePointSitesOptions id={site.site_id} />
                        </td>
                      </Fragment>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className={styles.NoSites}>
              {sites.length === 0 && <Text size='xs'>Add sites to start syncing</Text>}
            </div>
          </div>
        </div>
      </PageLoader>
    </Fragment>
  )
}

export default SharePointSites
