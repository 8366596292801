import useToggleState from 'Utils/hooks/useToggleState'

import { useAppDetailContext } from '../../AppDetailContext'
import InstallApp, { InstallAppBase } from '../../../components/InstallApp'
import JiraAuthForm from './JiraAuthForm'

import { Fragment } from 'react'
import { Heading } from '@happyfoxinc/react-ui'

const InstallJira = ({ app }) => {
  const { title } = useAppDetailContext()
  const [showCredentialsForm, toggleCrdentialsForm] = useToggleState(false)

  if (app.is_another_ticketing_integration_connected) {
    return (
      <InstallAppBase>
        <Fragment>
          <Heading level={2}>
            Only one ticketing integration can be connected at a time.
            <br />
            To install {title} please uninstall other ticketing integration that you have connected.
          </Heading>
        </Fragment>
      </InstallAppBase>
    )
  }

  if (showCredentialsForm) {
    return <JiraAuthForm onCancel={toggleCrdentialsForm} app={app} />
  }

  return <InstallApp name={title} onInstall={toggleCrdentialsForm} />
}

export default InstallJira
