import { createApi } from '@reduxjs/toolkit/query/react'

import { rtkBaseQuery } from '../../../../../../api'

export const jiraApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'jiraApi',
  tagTypes: ['Jira'],
  refetchOnMountOrArgChange: 5,
  endpoints: (builder) => ({
    getApp: builder.query({
      query: () => '/v1/apps/jira',
      providesTags: ['Jira']
    }),
    install: builder.mutation({
      query: (data) => {
        return {
          url: '/v1/apps/jira',
          method: 'POST',
          body: data
        }
      },
      invalidatesTags: ['Jira']
    }),
    uninstall: builder.mutation({
      query: () => {
        return {
          url: '/v1/apps/jira',
          method: 'DELETE'
        }
      },
      invalidatesTags: ['Jira']
    })
  })
})

export const { useGetAppQuery, useInstallMutation, useUninstallMutation } = jiraApi

export default jiraApi
