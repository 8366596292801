import { unstable_HistoryRouter as HistoryRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useEffect } from 'react'

import { configureReactModal } from './Modal'

import history from './history'

import Login from './pages/public/Login'
import Protected from './pages/protected'

const App = () => {
  useEffect(() => {
    configureReactModal()
  }, [])

  return (
    <HistoryRouter history={history} basename='app'>
      <div id='__page-root'>
        <Routes>
          <Route path='login' element={<Login />} />
          <Route path='/*' element={<Protected />} />
          <Route path='*' element={<Navigate to='/login' replace />} />
        </Routes>
      </div>
      <div id='__modal-root' />
    </HistoryRouter>
  )
}

export default App
