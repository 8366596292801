import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { TableOption, TableOptions } from '@happyfoxinc/react-ui'

import parseErrorMessage from 'Utils/error-message-parser'
import { useRemoveSimpplrSiteMutation } from '../simpplr.service'

const SimpplrSiteOptions = (props) => {
  const { id } = props
  const [removeSite] = useRemoveSimpplrSiteMutation()

  const handleRemoveClick = useCallback(() => {
    const yes = window.confirm('Are you sure to remove this page from syncing.')

    if (!yes) {
      return
    }

    const promise = removeSite(id).unwrap()
    toast.promise(promise, {
      loading: 'Removing page...',
      success: 'Page removed successfully.',
      error: parseErrorMessage('Unable to remove page. Try again...')
    })
  }, [removeSite, id])

  return (
    <TableOptions>
      <TableOption onClick={handleRemoveClick}>Remove</TableOption>
    </TableOptions>
  )
}

export default SimpplrSiteOptions
