import { Fragment, useMemo } from 'react'
import { Heading, Text, Button } from '@happyfoxinc/react-ui'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'

import styles from './AppsList.module.css'

import VerticalTabs from 'Layout/VerticalTabs'
import PageLoader from 'Components/PageLoader'
import { usePlanContext } from 'Components/Plan'

import AppTiles from '../AppTiles'
import appsList from '../apps-list'
import OtherTicketingSystem from '../AppDetails/apps/OtherTicketingSystem'
import { openUrl } from 'Utils/url'
import api from 'Services/api'

const tabs = [
  {
    label: 'All Apps',
    path: 'all'
  },
  {
    label: 'Knowledge Sources',
    path: 'knowledge-sources'
  },
  {
    label: 'Ticketing system',
    path: 'ticketing-system'
  }
]

const KNOWN_CATEGORIES = ['all', 'knowledge-sources', 'ticketing-system']

const AppTilesContainer = () => {
  const { category } = useParams()
  const { isLoading, data = [] } = api.useGetAppsQuery()

  const apps = useMemo(() => {
    const mergedApps = data
      .map((app) => {
        const appDetail = appsList.find((lApp) => lApp.key === app.key)

        if (!appDetail) {
          return undefined
        }

        return {
          ...app,
          ...appDetail
        }
      })
      .filter(Boolean)
      .sort((a, b) => a.status.localeCompare(b.status))

    if (category === 'all') {
      return mergedApps
    }

    return mergedApps.filter((app) => {
      if (Array.isArray(app.category)) {
        return app.category.includes(category)
      }

      return app.category === category
    })
  }, [data, category])

  if (isLoading) {
    return <PageLoader />
  }

  if (!KNOWN_CATEGORIES.includes(category)) {
    return <Navigate to='../all' replace />
  }

  return (
    <Fragment>
      {category === 'ticketing-system' && (
        <Routes>
          <Route path='other' element={<OtherTicketingSystem />} />
        </Routes>
      )}
      <AppTiles apps={apps} />
    </Fragment>
  )
}

const PlanUpgradeDetails = () => {
  const { planName, integrationsLimit, isFreePlan, isProPlan, isEnterprisePlan } = usePlanContext()
  const { isLoading, data: apps = [] } = api.useGetAppsQuery()

  const handleUpgradeClick = () => {
    openUrl('https://www.happyfox.com/assist-ai/')
  }

  if (isEnterprisePlan || isLoading) {
    return null
  }

  const connectedApps = apps
    .filter((app) => app.status === 'linked')
    .filter((app) => app.key !== 'other-ticketing-system')

  if (connectedApps.length >= integrationsLimit) {
    return (
      <Fragment>
        <p>You have reached the integrations limit on your {planName} plan.</p>
        <Text style='muted'>
          {isFreePlan &&
            'Upgrade now to connect to additional integrations. Premium integrations are available on the Pro and Enterprise plans'}
          {isProPlan && 'Upgrade now to enjoy unlimited integrations with Assist AI'}
        </Text>
        <Button className={styles.PlanUpgradeButton} variant='secondary' onClick={handleUpgradeClick}>
          Upgrade Plan
        </Button>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <p>
        You are currently connected to {connectedApps.length} out of {integrationsLimit} available integrations in your{' '}
        {planName} plan.
      </p>
      <Text style='muted'>You can connect more apps for the complete experience.</Text>
    </Fragment>
  )
}

const AppsList = () => {
  const { planName, integrationsLimit, isEnterprisePlan } = usePlanContext()

  return (
    <Fragment>
      <Heading>Apps</Heading>
      <Text style='muted'>
        Connect knowledge sources & ticketing apps to Assist AI. The number of apps that you can integrate is determined
        by your plan. <br />
        {!isEnterprisePlan && `The ${planName} plan allows up to ${integrationsLimit} standard integrations`}
      </Text>
      <Routes>
        <Route path='/*' element={<VerticalTabs tabs={tabs} sidebarContent={<PlanUpgradeDetails />} />}>
          <Route path=':category/*' index element={<AppTilesContainer />} />
          <Route path='*' element={<Navigate to='all' replace />} />
        </Route>
      </Routes>
    </Fragment>
  )
}

export default AppsList
