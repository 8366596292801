import { Fragment, useMemo, useState, useEffect } from 'react'
import { Heading, TableActions, PaginationControls, Th, Text } from '@happyfoxinc/react-ui'
import { useTable, usePagination } from 'react-table'

import styles from './NotionPages.module.scss'
import { useGetNotionSyncedPagesQuery } from '../notion.service'
import PageLoader from 'Components/PageLoader'
import debounce from 'Utils/debounce'
import NotionPageOptions from './NotionPageOptions'

const PAGE_SIZE = 15
const defaultApiParams = { offset: 0, limit: PAGE_SIZE }

const NotionPages = () => {
  const [queryParams, setQueryParams] = useState(defaultApiParams)

  const { isLoading, data = {} } = useGetNotionSyncedPagesQuery(queryParams)
  const { results: pages = [], meta: paginationDetails = {} } = data

  const debouncedSetQueryParams = useMemo(() => {
    return debounce(setQueryParams, 500, { leading: true, trailing: true })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Pages',
        accessor: 'title',
        disableSortBy: true
      }
    ]
  }, [])

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data: pages,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE
      },
      // pagination
      manualPagination: true,
      pageCount: Math.ceil(paginationDetails.total / PAGE_SIZE)
    },
    usePagination
  )

  useEffect(() => {
    const params = {}

    const apiParams = {
      offset: pageIndex * PAGE_SIZE,
      limit: PAGE_SIZE,
      ...params
    }

    debouncedSetQueryParams(apiParams)
  }, [pageIndex, debouncedSetQueryParams])

  return (
    <Fragment>
      <Heading level={2}>Pages currently syncing from</Heading>
      <PageLoader isLoading={isLoading}>
        <div className={styles.TableContainer}>
          <TableActions>
            <PaginationControls
              currentPage={pageIndex + 1}
              pageSize={PAGE_SIZE}
              totalItems={paginationDetails.total}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              previousPage={previousPage}
              nextPage={nextPage}
            />
          </TableActions>
          <table className={styles.Table} {...getTableProps()}>
            <thead className={styles.Th}>
              {headerGroups.map((headerGroup) => {
                const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

                return (
                  <tr key={key} {...headerGroupProps}>
                    <Fragment>
                      {headerGroup.headers.map((column) => {
                        const { key, ...headerProps } = column.getHeaderProps()

                        return (
                          <th className={styles.Th} key={key} {...headerProps}>
                            {column.render('Header')}
                          </th>
                        )
                      })}
                      <Th isOption />
                    </Fragment>
                  </tr>
                )
              })}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                const { key, ...rowProps } = row.getRowProps()
                const page = row.original

                return (
                  <tr key={key} {...rowProps}>
                    <Fragment>
                      {row.cells.map((cell) => {
                        const { key, ...cellProps } = cell.getCellProps()
                        return (
                          <td className={styles.Td} key={key} {...cellProps}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                      <td>
                        <NotionPageOptions id={page.id} />
                      </td>
                    </Fragment>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {pages.length === 0 && <Text>Currently not syncing any pages. Select pages to start syncings</Text>}
        </div>
      </PageLoader>
    </Fragment>
  )
}

export default NotionPages
