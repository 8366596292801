export const openUrl = (url) => {
  return window.open(url, '_blank')
}

export const externalRedirect = (url, confirmBeforeRedirect = true) => {
  const domain = new URL(url).origin

  if (confirmBeforeRedirect) {
    const yes = window.confirm(`Are you sure to redirect to this URL? URL: ${domain}`)
    if (!yes) {
      return false
    }
  }

  window.location.href = url
}

export const redirect = (path) => {
  const normalizedPath = `app/${path}`.replace(/\/{2,}/gi, '/')
  const url = new URL(normalizedPath, window.location.origin)
  window.location.href = url.href
}
