import * as yup from 'yup'

const inviteAgentValidationSchema = yup
  .object()
  .shape({
    email: yup.string().email().required('Email is required')
  })
  .required()

export default inviteAgentValidationSchema
