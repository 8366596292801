import useToggleState from 'Utils/hooks/useToggleState'

import { useAppDetailContext } from '../../AppDetailContext'
import InstallApp from '../../../components/InstallApp'
import GuruAuthForm from './GuruAuthForm'

const InstallGuru = ({ app }) => {
  const { title } = useAppDetailContext()
  const [showCredentialsForm, toggleCrdentialsForm] = useToggleState(false)

  if (showCredentialsForm) {
    return <GuruAuthForm onCancel={toggleCrdentialsForm} app={app} />
  }

  return <InstallApp name={title} onInstall={toggleCrdentialsForm} />
}

export default InstallGuru
