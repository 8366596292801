import { Heading, Button } from '@happyfoxinc/react-ui'

import styles from './Login.module.css'
import ChatBubble from 'Images/happyfox-assist-ai-logo.svg'
import SlackIcon from 'Images/brands/slack.svg'

import { externalRedirect } from 'Utils/url'

const Login = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.Cover}>
        <div className={styles.CoverContent}>
          <div className={styles.CoverDisplay} />
          <div className={styles.CoverInfo}>
            <p className={styles.CoverTitle}>Assist AI</p>
            <p className={styles.CoverText}>Leverage AI to simplify workplace request management and resolution.</p>
          </div>
        </div>
      </div>
      <div className={styles.Content}>
        <div className={styles.ContentCenter}>
          <ChatBubble className={styles.Logo} />
          <Heading>Sign in to Assist AI</Heading>
          <a href='/app/login/slack/start' className={styles.SlackButton}>
            <SlackIcon width='24' height='24' /> Sign in with Slack
          </a>
          <div className={styles.NewCustomer}>
            Not a customer?{' '}
            <Button
              className={styles.TryButton}
              variant='default'
              size='xs'
              onClick={() => externalRedirect('https://tenmilers.slack.com/apps/A01G3QXNBDM-assist-ai', false)}
            >
              Try for free
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
