import { Text, Input, Button, FormErrorText, FormControl } from '@happyfoxinc/react-ui'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { nanoid } from '@reduxjs/toolkit'

import styles from './OtherTicketingSystem.module.css'

import Modal, { ModalHeader, ModalBody } from 'Components/Modal'
import PageLoader from 'Components/PageLoader'
import { useGetAppQuery, useUpdateMutation, useDeleteMutation } from './ots.service'
import otsFormValidationSchema from './ots-form-validation-schema'
import parseErrorMessage from 'Utils/error-message-parser'
import api from 'Services/api'

const toastId = nanoid()

const OtherTicketingSystem = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      url: ''
    },
    resolver: yupResolver(otsFormValidationSchema)
  })

  const { isLoading, data: app = {} } = useGetAppQuery()
  const [updateApp, updateAppResult] = useUpdateMutation()
  const [deleteApp, deleteAppResult] = useDeleteMutation()

  const handleClose = () => {
    navigate('../')
  }

  useEffect(() => {
    setValue('url', app.new_ticket_url ?? '')
  }, [setValue, app.new_ticket_url])

  const onSubmit = async (data) => {
    try {
      const promise = updateApp(data.url).unwrap()

      toast.promise(
        promise,
        {
          loading: 'Updating Other ticketing system',
          success: 'Updated Other ticketing system successfully',
          error: parseErrorMessage('Unable to update other ticketing system URL. Try again...')
        },
        {
          id: toastId
        }
      )

      await promise
      dispatch(api.util.invalidateTags(['Apps']))
    } catch {}
  }

  const onDelete = async () => {
    const yes = window.confirm('Are you sure to disconnect this app?')

    if (!yes) {
      return
    }

    try {
      const promise = deleteApp().unwrap()

      toast.promise(
        promise,
        {
          loading: 'Disconnecting Other ticketing system',
          success: 'Disconnecting Other ticketing system successfully',
          error: parseErrorMessage('Unable to disconnect ticketing system. Try again...')
        },
        {
          id: toastId
        }
      )

      await promise
      dispatch(api.util.invalidateTags(['Apps']))
    } catch {}
  }

  return (
    <Modal isOpen style={{ content: { width: 480 } }}>
      <ModalHeader onClose={handleClose}>
        <span className={styles.Title}>Connect to other ticketing system</span>
      </ModalHeader>
      <PageLoader isLoading={isLoading}>
        <ModalBody>
          <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
            <Text style='muted'>
              Enter the New Ticket Form URL of your ticketing system here. Assist AI will redirect the user who wants to
              raise a ticket to this link.
            </Text>
            <FormControl>
              <Input placeholder='https://support.happyfox.com/new' autoFocus {...register('url')} />
              {errors.url && <FormErrorText>{errors.url.message}</FormErrorText>}
            </FormControl>
            <div className={styles.ButtonContainer}>
              <Button type='submit' variant='primary' disabled={updateAppResult.isLoading}>
                {app.is_linked ? 'Update' : 'Connect'}
              </Button>
              {app.is_linked && (
                <Button
                  variant='link-danger'
                  className={styles.DisconnectButton}
                  disabled={deleteAppResult.isLoading || updateAppResult.isLoading}
                  onClick={onDelete}
                >
                  Disconnect
                </Button>
              )}
            </div>
          </form>
        </ModalBody>
      </PageLoader>
    </Modal>
  )
}

export default OtherTicketingSystem
