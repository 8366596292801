import PageLoader from 'Components/PageLoader'
import { Fragment } from 'react'

import { useGetAppQuery } from './sharepoint.service'

import InstallSharePoint from './InstallSharePoint'
import ManageSharePoint from './ManageSharepoint'

const SharePoint = () => {
  const { isLoading, data: app = {} } = useGetAppQuery()

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <Fragment>
      {!app.is_linked && <InstallSharePoint app={app} />}
      {app.is_linked && <ManageSharePoint app={app} />}
    </Fragment>
  )
}

export default SharePoint
