import SharePointLogo from 'Images/brands/microsoft-sharepoint.svg'
import JiraLogo from 'Images/brands/jira.svg'
import HappyFoxLogo from 'Images/brands/happyfox.svg'
import NotionLogo from 'Images/brands/notion.svg'
import ConfluenceLogo from 'Images/brands/confluence.svg'
import SimpplrLogo from 'Images/brands/simpplr.svg'
import GuruLogo from 'Images/brands/guru.svg'
import OtsImage from 'Images/ots.svg'

const apps = [
  {
    key: 'sharepoint', // used by BE
    id: 'sharepoint',
    category: 'knowledge-sources',
    title: 'SharePoint',
    description:
      'Sharepoint is used as a secure place to store, organize, share, and access information from any device. All you need is a web browser and you are good to go.',
    logo: SharePointLogo,
    kbLink: '',
    planCategory: 'standard'
  },
  {
    key: 'happyfox',
    id: 'happyfox',
    category: ['ticketing-system', 'knowledge-sources'],
    title: 'HappyFox Helpdesk',
    description:
      'HappyFox is an all-in-one help desk ticketing system that provides faster and better support for your customers.',
    logo: HappyFoxLogo,
    kbLink: '',
    planCategory: 'standard'
  },
  {
    key: 'notion',
    id: 'notion',
    category: 'knowledge-sources',
    title: 'Notion',
    description:
      'Notion is a project management and note-taking software.Notion is a software designed to help members of a company or organization coordinate deadlines, objectives, and assignments for the sake of efficiency and productivity.',
    logo: NotionLogo,
    kbLink: '',
    planCategory: 'standard'
  },
  {
    key: 'simpplr',
    id: 'simpplr',
    category: 'knowledge-sources',
    title: 'Simpplr',
    description:
      'Simpplr helps companies connect their workforce by streamlining internal communications and forging employee connections',
    logo: SimpplrLogo,
    kbLink: '',
    planCategory: 'premium'
  },
  {
    key: 'jira_service_management',
    id: 'jira',
    category: 'ticketing-system',
    title: 'Jira Service Desk',
    description:
      'Jira Service Management, is a system which is used to easily receive, track, manage, and resolve requests from customers.',
    logo: JiraLogo,
    kbLink: '',
    planCategory: 'standard'
  },
  {
    key: 'confluence',
    id: 'confluence',
    category: 'knowledge-sources',
    title: 'Confluence',
    description:
      'Confluence is a team collaboration tool that gives your team a place to create, capture, and collaborate on any project or idea',
    logo: ConfluenceLogo,
    kbLink: '',
    planCategory: 'standard'
  },
  {
    key: 'guru',
    id: 'guru',
    category: 'knowledge-sources',
    title: 'Guru',
    description:
      'Guru is a collaborative knowledge management solution that is used to share knowledge within a company. With Guru, teams can effortlessly access knowledge from across your company.',
    logo: GuruLogo,
    kbLink: '',
    planCategory: 'standard'
  },
  {
    key: 'other-ticketing-system',
    id: 'other-ticketing-system',
    route: 'category/ticketing-system/other',
    category: 'ticketing-system',
    title: 'Other Ticketing system',
    description: 'Connect to other ticketing system',
    logo: OtsImage,
    kbLink: '',
    planCategory: 'standard'
  }
]

export default apps
